import React, { useState, useEffect } from "react";
import Translator, { __ } from "services/Translator";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import api from "services/api";

export default function OpenChat(props) {
  const history = useHistory();
 

  return (
    <div className=" open_chat">
      <Dropdown>
        <Dropdown.Toggle>
          <img
            className="mainIcon"
            src={
              require("../assets/images/icons/chat-communication.svg").default
            }
          />
          <img
            className="hoverIcon"
            src={
              require("../assets/images/icons/chat-communication-gold.svg")
                .default
            }
          />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="modal_top_arrow"></div>
          <Dropdown.Item
            onClick={() => {
              props.setIsChatVisible(false);
              props.setSupportSidebarOpen(true);
            }}
          >
            {__("Support Chat")}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.setIsChatVisible(true);
              props.setSupportSidebarOpen(false);
            }}
          >
            {__("Players forum")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

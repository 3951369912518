import React, { useState, useEffect } from "react";
import {
  Redirect,
  Route,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "local-storage";
import { Badge, Table, Modal } from "react-bootstrap";

import CategoryGameSlider from "elements/games_elements/CategoryGameSlider";
import HomeBanner from "elements/HomeBanner";
import CategoryGames from "elements/games_elements/CategoryGames";
import GamesFilter from "elements/games_elements/GamesFilter";
import { __ } from "services/Translator";

export default function Landing(props) {
  const [winnerTab, setWinnerTab] = useState("Recent Winners");
  const history = useHistory();
  const location = useLocation();
  const [gameList, setGameList] = useState([]);

  const justActivated = props.activated ? true : false;

  const [gameSorting, setGameSorting] = useState(null);
  const [pagination, setPagination] = useState(null);
  // const [perPage, setPerPage] = useState(18);
  const [gameLoaded, setGameLoaded] = useState(0);
  const [totalGame, setTotalGame] = useState(0);

  const authResult = new URLSearchParams(window.location.search);
  const afftoken = authResult.get("afftoken");

  const socket = props.socket;

  const getGameList = async (page) => {
    var perPage = 20;

    if (window.innerWidth < 992) {
      perPage = 20;
    }

    if (window.innerWidth < 768) {
      perPage = 21;
    }
    if (window.innerWidth < 576) {
      perPage = 18;
    }

    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      game_types: ["popular"],
      page: page,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setGameList(response.data.games?.popular?.gamelist ?? []);

      if (page === 1) {
        setGameList(response.data.games?.popular?.gamelist ?? []);
      } else {
        var gamesClone = JSON.parse(JSON.stringify(gameList));
        console.log("gamesClone", gamesClone);
        gamesClone = [
          ...gamesClone,
          ...response.data.games["popular"].gamelist,
        ];
        setGameList(gamesClone);
      }

      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }

      setGameLoaded(page * perPage);
      setTotalGame(response.data.gameCount);
    }
  };

  useEffect(() => {
    getGameList(1);
  }, [gameSorting, props.selectedProviders]);

  useEffect(() => {
    if (afftoken) {
      ls.set("afftoken", afftoken);
      // history.push("/");
    }
  }, [afftoken]);

  // const [winnerTab, setWinnerTab] = useState("Recent Winners");

  const [recentWinners, setRecentWinners] = useState([]);
  const getRecentWinners = async () => {
    var response = await api.get("/games/recent-winners", { limit: 7 });
    if (response.status === 200) {
      setRecentWinners(response.data.data);
      // console.log('response.data', response.data);
    }
  };

  const [topWinners, setTopWinners] = useState([]);
  const getTopWinners = async () => {
    var response = await api.get("/games/top-winners", { limit: 7 });
    if (response.status === 200) {
      setTopWinners(response.data.data);
    }
  };

  const checkIframe = () => {
    if (window.parent.parent.location.href != window.location.href) {
      window.parent.location.href = window.location.href;
    } else {
    }
  };

  useEffect(async () => {
    getTopWinners();
    getRecentWinners();
    checkIframe();

    var timeout;

    timeout = setInterval(() => {
      getTopWinners();
      getRecentWinners();
    }, 15000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const getRecentWinsFromSocket = (data) => {
    var winners = JSON.parse(JSON.stringify(recentWinners));
    winners.unshift(JSON.parse(data));
    if (winners.length > 10) {
      winners.splice(10, winners.length - 10);
    }
    setRecentWinners(winners);
  };

  useEffect(() => {
    socket.client.on("win", getRecentWinsFromSocket);
    return () => {
      socket.client.off("win");
    };
  }, [socket?.client?.connected, recentWinners]);

  const onPageChange = (page) => {
    getGameList(page);
  };

  useEffect(() => {
    if (location.pathname === "/signup") {
      props.setModalSignUpPropsData(true);
      setTimeout(function () {
        props.setModalSignUpPropsData(false);
      }, 500);
    }
  }, []);

  const closeAllModals = () => {
    history.push("/");
  };

  return (
    <>
      <Helmet>
        <title>
          {__("World Golden Casino | Sign Up today for a 100% of up to 1BTC!")}
        </title>
        <meta
          name="description"
          content={__(
            "World Golden Casino! Sign Up today and receive bonuses on your first 2 Deposits totalling 2.5 BTC - Double your Coins on us!"
          )}
        />
      </Helmet>
      {props.isAuthenticated || <HomeBanner {...props} />}

      <GamesFilter {...props} />
      <CategoryGames
        providers={props.providers}
        selectedProviders={props.selectedProviders}
        setSelectedProviders={props.setSelectedProviders}
        gameList={gameList}
        categoryName="Popular Games"
        category="popular"
        setGameSorting={setGameSorting}
        gameSorting={gameSorting}
        isAuthenticated={props.isAuthenticated}
        gameLoaded={gameLoaded}
        totalGame={totalGame}
        showPagination={true}
        pagination={pagination}
        onPageChange={onPageChange}
        setSearchStr={props.setSearchStr}
      />
      <CategoryGameSlider {...props} />
      <div className="sports_section">
        <div className="sports_betting">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-5 col-xl-4">
                <div className="sports_betting_text">
                  <br />
                  <div className="headline">
                    <span className="light_blue">{__("T")}</span>
                    {__("hrilling")}
                  </div>
                  <div className="title">Casino Games</div>
                  <div className="paragraph white">
                    {__(
                      " offers over 4.000 (!) of exciting slots and live casino games, provided by the world's best provider such as Pragmatic Play, NetEnt, Microgaming, Evolution, Betsoft, and many more!"
                    )}
                  </div>
                  {props.isAuthenticated ? (
                    <Link to="/games/all" className="common_btn no_margin">
                      {__("Play Now")}
                    </Link>
                  ) : (
                    <Link
                      onClick={() => {
                        props.setModalSignUpPropsData(true);
                        setTimeout(function () {
                          props.setModalSignUpPropsData(false);
                        }, 500);
                      }}
                      className="common_btn no_margin"
                    >
                      {__("Quick Registration")}
                    </Link>
                  )}
                  <br />

                  <img
                    className="sports_betting_img d-block d-md-none"
                    src={
                      require("assets/images/banners/sports_banner2.png")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="col-12 d-none d-md-block col-md-7 col-lg-7 col-xl-8">
                <div className="sports_betting_img">
                  <img
                    src={
                      require("assets/images/banners/sports_banner2.png")
                        .default
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sports_help_banner">
        <div className="container">
          <section className="winner_section">
            <br />
            <div className="winner_toggle">
              <Link
                onClick={() => {
                  setWinnerTab("Recent Winners");
                }}
                className={
                  winnerTab === "Recent Winners"
                    ? "toggle_btn common_btn active"
                    : "toggle_btn"
                }
              >
                {__("Recent Winners")}
              </Link>
              <Link
                onClick={() => {
                  setWinnerTab("Top Winners");
                }}
                className={
                  winnerTab === "Top Winners"
                    ? "toggle_btn common_btn active"
                    : "toggle_btn"
                }
              >
                {__("Big Winners")}
              </Link>
            </div>
            <div className="winner_table">
              <Table>
                <thead>
                  {!props.isMobileDeviceByViewPort ? (
                    <tr>
                      <td>{__("Game")}</td>
                      <td>{__("User")}</td>
                      <td>{__("Bet")}</td>
                      <td>{__("Win")}</td>
                      <td>{__("Profit")}</td>
                    </tr>
                  ) : (
                    <tr>
                      <td>{__("Game and Player")}</td>
                      <td>{__("Amounts")}</td>
                    </tr>
                  )}
                </thead>

                <tbody>
                  {winnerTab === "Recent Winners" && (
                    <>
                      {recentWinners.length > 0 &&
                        recentWinners.map((value, index) => (
                          <React.Fragment key={index}>
                            {props.isMobileDeviceByViewPort ? (
                              <tr>
                                <td>
                                  <Link
                                    to={"/play/" + value.game?.slug}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <img src={value.game?.game_snapshot_url} />
                                    <div>
                                      {value.game?.game_name} <hr />
                                      {value.player?.player_nickname
                                        ? value.player?.player_nickname
                                        : __("Anonymous")}
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  Bet: ${value.bet_amount}
                                  <hr />
                                  Win: ${value.win_amount}
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td>
                                  <Link to={"/play/" + value.game?.slug}>
                                    <img src={value.game?.game_snapshot_url} />
                                    {props.isMobileDeviceByViewPort
                                      ? value.game?.game_name.substring(0, 9) +
                                        "..."
                                      : value.game?.game_name}
                                  </Link>
                                </td>
                                <td>
                                  {value.player?.player_nickname
                                    ? value.player?.player_nickname
                                    : __("Anonymous")}
                                </td>
                                <td>${value.bet_amount}</td>
                                <td>${value.win_amount}</td>
                                <td>${value.profit}</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                    </>
                  )}
                  {winnerTab === "Top Winners" && (
                    <>
                      {topWinners.length > 0 &&
                        topWinners.map((value, index) => (
                          <React.Fragment key={index}>
                            {props.isMobileDeviceByViewPort ? (
                              <tr>
                                <td>
                                  <Link
                                    to={"/play/" + value.game?.slug}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <img src={value.game?.game_snapshot_url} />
                                    <div>
                                      {value.game?.game_name} <hr />
                                      {value.player?.player_nickname
                                        ? value.player?.player_nickname
                                        : __("Anonymous")}
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  Bet: ${value.bet_amount}
                                  <hr />
                                  Win: ${value.win_amount}
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td>
                                  <Link to={"/play/" + value.game?.slug}>
                                    <img src={value.game?.game_snapshot_url} />
                                    {props.isMobileDeviceByViewPort
                                      ? value.game?.game_name.substring(0, 9) +
                                        "..."
                                      : value.game?.game_name}
                                  </Link>
                                </td>
                                <td>
                                  {value.player?.player_nickname
                                    ? value.player?.player_nickname
                                    : __("Anonymous")}
                                </td>
                                <td>${value.bet_amount}</td>
                                <td>${value.win_amount}</td>
                                <td>${value.profit}</td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </section>

          <br />
          <div className="row d-none">
            <div className="col-12 col-sm-6 col-md-7 col-lg-8 align-item-center">
              <div className="video_slider">
                <img
                  src={
                    require("assets/images/banners/sport-help-banner.png")
                      .default
                  }
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-5 col-lg-4">
              <div className="upcoming_videos">
                <div className="single_video">
                  <Link to="#">
                    <div className="video_thumb">
                      <Badge className="video_badge">{__("Top")}</Badge>
                      <img
                        src={require("assets/images/games/games3.png").default}
                      />
                    </div>
                    <div className="video_name">{__("Cyber Hunter")}</div>
                    <div className="video_description">
                      {__("lorem ipsum dummy")}
                    </div>
                  </Link>
                </div>
                <div className="single_video">
                  <Link to="#">
                    <div className="video_thumb">
                      <Badge className="video_badge">{__("Top")}</Badge>
                      <img
                        src={require("assets/images/games/games3.png").default}
                      />
                    </div>
                    <div className="video_name">{__("Cyber Hunter")}</div>
                    <div className="video_description">
                      {__("lorem ipsum dummy")}
                    </div>
                  </Link>
                </div>
                <div className="single_video">
                  <Link to="#">
                    <div className="video_thumb">
                      <Badge className="video_badge">{__("Top")}</Badge>
                      <img
                        src={require("assets/images/games/games3.png").default}
                      />
                    </div>
                    <div className="video_name">{__("Cyber Hunter")}</div>
                    <div className="video_description">
                      {__("lorem ipsum dummy")}
                    </div>
                  </Link>
                </div>
                <div className="single_video">
                  <Link to="#">
                    <div className="video_thumb">
                      <Badge className="video_badge">{__("Top")}</Badge>
                      <img
                        src={require("assets/images/games/games3.png").default}
                      />
                    </div>
                    <div className="video_name">{__("Cyber Hunter")}</div>
                    <div className="video_description">
                      {__("lorem ipsum dummy")}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="gamePlayMsgModal"
        show={justActivated}
        onHide={closeAllModals}
      >
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {__("Welcome")}!
          </h5>
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            <i
              class="fas fa-check"
              style={{ color: "green", fontSize: "100px" }}
            ></i>
            <br />
            {__("Your account is now activated. You can now login.")}
            <br />
            {__("Have fun!")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="common_btn" onClick={closeAllModals}>
            {__("OK")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "services/api";
import Translator, { __ } from "services/Translator";
import swal from "sweetalert";

export default function Sports(props) {
  const history = useHistory();
  const isAuthenticated = props.isAuthenticated;
  const isMobileDevice = props.isMobileDevice;
  const isMobileDeviceByViewPort = props.isMobileDeviceByViewPort;
  const [launchUrl, setLaunchUrl] = useState("");
  const [gameDetails, setGameDetails] = useState("");
  const [gameSessionId, setGameSessionId] = useState(null);
  const isLandscape = props.isLandscape;
  const [mobileIframeStyle, setMobileIframeStyle] = useState({});
  const calclateMobileIframeStyle = () => {
    var style = {
      width: "100vw",
      height: "calc(100vh - 52px)",
    };
    if (isLandscape) {
      style.height = "100vh";
    }
    setMobileIframeStyle(style);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      calclateMobileIframeStyle();
    });
    calclateMobileIframeStyle();

    window.gotoGamesPage = () => {
      history.push("/");
    };
  }, []);

  const initSports = async () => {
    var response = await api.post("/game/initsession", {
      slug: "sportsbook",
      mode: "real",
    });

    if (response.status === 200 && response.data) {
      setLaunchUrl(response.data.launchUrl);
      setGameSessionId(response.data.sessionId);
      setGameDetails(response.data.details);
    } else {
      props.showAlert("Warning!!", response.data.message, true, () => {
        // history.push("/play/" + params.slug + "/demo");
      });
    }
  };

  useEffect(() => {
    initSports();
  }, []);

  return (
    <>
      {isMobileDevice ? (
        <iframe src={launchUrl} style={mobileIframeStyle} />
      ) : (
        <>
          <section className="about">
            <div className="container">
              <div className="terms_content">
                <iframe
                  id="sports_bet_window"
                  width="100%"
                  height="100%"
                  style={{ minHeight: 600, width: "100%", height: "100vh", display: "block !important" }}
                  src={launchUrl}
                ></iframe>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import LoadMore from "elements/LoadMore";
import GameItem from "elements/games_elements/GameItem";
import SearchBox from "elements/SearchBox";
import { Redirect, Route, Link, useParams, useHistory } from "react-router-dom";
import { Badge, Table, Modal, Collapse } from "react-bootstrap";
import api from "services/api";
import { __ } from "services/Translator";

import JackpotTicker from "elements/JackpotTicker";

export default function CategoryGames(props) {
  const params = useParams();
  const category = params.category;
  const history = useHistory();
  const providers = props.providers;
  const selectedProviders = props.selectedProviders;
  const setSelectedProviders = props.setSelectedProviders;

  const [providersModal, setProvidersModal] = useState(false);
  const closeAllModals = () => {
    setProvidersModal(false);
  };
  const toggleProviersModal = () => {
    setProvidersModal(!providersModal);
  };
  const toggleSelectedProvider = (providerId) => {
    var providersClone = JSON.parse(JSON.stringify(selectedProviders));
    if (providersClone.indexOf(providerId) === -1) {
      providersClone.push(providerId);
    } else {
      providersClone.splice(providersClone.indexOf(providerId), 1);
    }
    setSelectedProviders(providersClone);
    history.push("/games/all");
  };

  const clearAllProviders = () => {
    setSelectedProviders([]);
  };
  // const __ = (text) => text;

  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const searchTextBox = useRef(null);

  // expand searcbar
  const [expandSearchBar, setExpandSearchBar] = useState(true);

  const toggleExpandedSearchBar = (force) => {
    if (expandSearchBar) {
      setSearchSuggestions([]);
      if (force === true) {
        setSearchStr("");
        setExpandSearchBar(false);
      } else {
        if (searchStr.length === 0) {
          setExpandSearchBar(false);
        }
      }
    } else {
      setExpandSearchBar(true);
      searchTextBox.current.focus();
    }
  };

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        // providers: props.selectedProviders,
        providers: [],
        extensiveInfo: true,
      });
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    getSearchSuggestions();
  }, [searchStr]);

  const doSearchByGameName = (text) => {
    props.setSearchStr(text);
    toggleExpandedSearchBar(true);
    props.setSelectedProviders([]);
    history.push("/games/search");
  };

  return (
    <>
      <div className="container">
        <div className="category_games_header">
          <div className="leftSide">
            {props.category == "jackpot" && (
              <>
                <div className="header_jackpot_area">
                  <div className="jackpot_title">{__("JACKPOTS TOTAL")}</div>
                  <JackpotTicker {...props} />
                </div>
              </>
            )}
          </div>
          <div className="rightSide">
            <div className="headline_left">
              <h4 className="game_headline d-none ">{props.categoryName}</h4>
            </div>
            <div> </div>

            <div className="headline_right ">
              <Link
                onClick={toggleProviersModal}
                className={
                  "common_btn medium " +
                  (props.selectedProviders.length ? "" : "borderd")
                }
              >
                {__("Game Providers")}
              </Link>
            </div>
            <SearchBox {...props} />
          </div>
        </div>

        <div className="game_list">
          {props.gameList.map((game, index) => (
            <GameItem
              game={game}
              isAuthenticated={props.isAuthenticated}
              key={index}
              {...props}
            />
          ))}
        </div>

        {props.showPagination && (
          <>
            <LoadMore
              data={props.pagination}
              onPageChange={props.onPageChange}
            />

            <div className="gameLoadingProgress">
              <div className="gameLoadingInfo">
                {__("You've viewed")} {props.gameLoaded} {__("of")}{" "}
                {props.totalGame} {__("games")}
                {/* <div className="gameLoaded">{props.gameLoaded}</div>
                <div className="totalGame"> {props.totalGame}</div> */}
              </div>

              <ProgressBar
                variant="success"
                now={(props.gameLoaded / props.totalGame) * 100}
              />
            </div>
          </>
        )}
      </div>

      <Modal size="lg" show={providersModal} onHide={closeAllModals}>
        <Modal.Body className="providers_modal">
          <div onClick={closeAllModals} className="modal_close">
            <i className="fal fa-times"></i>
          </div>

          <div className="modal_headline">{__("Filter By Providers")}</div>
          <div className="providers_list">
            <Link
              onClick={clearAllProviders}
              className={
                "common_btn small " +
                (selectedProviders.length === 0 ? "active" : "inactive")
              }
            >
              {__("All Providers")}
            </Link>

            {providers.map((item, index) => (
              <Link
                onClick={() => toggleSelectedProvider(item.provider_id)}
                key={index}
                className={
                  "common_btn small " +
                  (item.name.length > 12 ? "smallText " : "") +
                  (selectedProviders.indexOf(item.provider_id) !== -1
                    ? "active"
                    : "inactive")
                }
              >
                {item.name}
              </Link>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

import Translator, { __ } from "services/Translator";

//Auth module
import PrivateRoute from "routes/PrivateRoute";
import OpenRoute from "routes/OpenRoute";
import ChatRoute from "routes/ChatRoute";

// import external modules
import React, { Component, lazy, useEffect } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import Landing from "pages/Landing";
import SignUp from "pages/Landing";
import UserDashboard from "pages/UserDashboard";
import Promotions from "pages/Promotions";
import Games from "pages/Games";
import GamePlay from "pages/GamePlay";
import FAQ from "pages/FAQ";
import Vip from "pages/Vip";
import Page from "pages/Page";
import Sports from "pages/Sports";
import Races from "pages/Races";
import LangFixer from "pages/LangFixer";

import Chat from "elements/Chat";

// auth pages
import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import RecoverPassword from "pages/auth/RecoverPassword";
import ResetPassword from "pages/auth/ResetPassword";

import Blogs from "../pages/Blogs";
import BlogDetails from "../pages/BlogDetails";

// const Landing = lazy(() => import("pages/Landing"));
// import { BrowserRouter, Switch, Redirect } from "react-router-dom";
function OpenRoutes() {
  // const match = useRouteMatch("/:lang/:rest");
  // console.log(match);

  return (
    <BrowserRouter basename={"/" + Translator.getSelectedLanguage().code + "/"}>
      <Switch>
        <OpenRoute exact path="/" component={Landing} />
        <OpenRoute exact path="/account-activated" activated={true} component={Landing} />
        <OpenRoute exact path="/signup" component={SignUp} />
        <OpenRoute exact path="/promotions" component={Promotions} />
        <OpenRoute exact path="/terms" component={Page} />
        <OpenRoute exact path="/bonus-terms" component={Page} />
        <OpenRoute exact path="/privacy" component={Page} />
        <OpenRoute exact path="/aml-policy" component={Page} />
        <OpenRoute exact path="/legal" component={Page} />
        <OpenRoute exact path="/vip-program" component={Page} />
        <OpenRoute exact path="/vip" component={Vip} />
        <OpenRoute exact path="/faq" component={FAQ} />
        <OpenRoute exact path="/sports" component={Sports} />
        <OpenRoute exact path="/games/:category" component={Games} />
        <OpenRoute exact path="/play/:slug/:mode?" component={GamePlay} />

        {/* auth pages */}
        <OpenRoute exact path="/login" component={Login} />
        <OpenRoute exact path="/register" component={Register} />
        <OpenRoute exact path="/recover-password" component={RecoverPassword} />
        <OpenRoute exact path="/reset-password/:userId/:code" component={ResetPassword} />
        <OpenRoute exact path="/races" component={Races} />
        <OpenRoute exact path="/blogs" component={Blogs} />
        <OpenRoute exact path="/blog-details/:slug" component={BlogDetails} />
        <OpenRoute path="*" component={LangFixer} />
      </Switch>
    </BrowserRouter>
  );
}

class PrivateRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename={"/" + Translator.getSelectedLanguage().code + "/"}>
        <Switch>
          <PrivateRoute exact path="/" component={Landing} />
          <PrivateRoute exact path="/games/:category" component={Games} />
          <PrivateRoute exact path="/sports" component={Sports} />
          <PrivateRoute exact path="/play/:slug/:mode?" component={GamePlay} />
          <PrivateRoute exact path="/dashboard/:tab?" component={UserDashboard} />

          <PrivateRoute exact path="/promotions" component={Promotions} />

          <PrivateRoute exact path="/vip" component={Vip} />
          <PrivateRoute exact path="/faq" component={FAQ} />
          <PrivateRoute exact path="/terms" component={Page} />
          <PrivateRoute exact path="/bonus-terms" component={Page} />
          <PrivateRoute exact path="/privacy" component={Page} />
          <PrivateRoute exact path="/aml-policy" component={Page} />
          <PrivateRoute exact path="/legal" component={Page} />
          <PrivateRoute exact path="/vip-program" component={Page} />
          <ChatRoute exact path="/chat" component={Chat}></ChatRoute>
          <PrivateRoute exact path="/races" component={Races} />

          <PrivateRoute exact path="/blogs" component={Blogs} />
          <PrivateRoute exact path="/blog-details/:slug" component={BlogDetails} />

          <PrivateRoute path="*" component={LangFixer} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export { OpenRoutes, PrivateRoutes };

import React, { useState, useEffect, useContext, useRef } from "react";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import emailValidate from "services/emailValidate";
import { Modal, Button, Alert, Dropdown, Form, Overlay, Tooltip, Collapse, Accordion } from "react-bootstrap";
import { Redirect, Route, Link, useParams, useHistory, useLocation } from "react-router-dom";
// custom component
import LeftSidebar from "elements/LeftSidebar";
import RightSidebar from "elements/RightSidebar";
import CashierModals from "elements/dashboard_elements/CashierModals";
import VaultModals from "elements/dashboard_elements/VaultModals";
import LoginReg from "elements/LoginReg";
import LanguageSwitcher from "elements/LanguageSwitcher";
import OpenChat from "elements/OpenChat";
import Translator, { __ } from "services/Translator";

const Header = (props) => {
  // const menuOpen = props.menuOpen;
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { updateUserObj } = useContext(AppContext);
  const gamesCategory = params.category;

  const isAuthenticated = props.isAuthenticated;
  const userBalance = props.userBalance;
  const getUserBalance = props.getUserBalance;

  const logout = async (ev) => {
    ev.preventDefault();
    await auth.logout();
    await updateUserObj();
  };

  const notificationCount = props.notificationCount;

  // userMenu
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const toggleRightSidebar = () => {
    setRightSidebarOpen(!rightSidebarOpen);
  };

  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);

  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const searchTextBox = useRef(null);

  // expand searcbar
  const [expandSearchBar, setExpandSearchBar] = useState(false);

  const toggleExpandedSearchBar = (force) => {
    if (expandSearchBar) {
      setSearchSuggestions([]);
      if (force === true) {
        setSearchStr("");
        setExpandSearchBar(false);
      } else {
        if (searchStr.length === 0) {
          setExpandSearchBar(false);
        }
      }
    } else {
      setExpandSearchBar(true);
      searchTextBox.current.focus();
    }
  };

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        providers: props.selectedProviders,
        extensiveInfo: true,
      });
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    getSearchSuggestions();
  }, [searchStr]);

  const doSearchByGameName = (text) => {
    props.setSearchStr(text);
    toggleExpandedSearchBar(true);
    history.push("/games/search");
  };

  //GamePlay Page detection

  const [showInPlay, setShowInPlay] = useState(false);
  const playPath = location.pathname.split("/");

  useEffect(() => {
    setShowInPlay(playPath.includes("play"));
  }, [playPath]);

  // const [modalSignUpPropsData, setModalSignUpPropsData] = useState(false);
  // const [modalLoginPropsData, setModalLoginPropsData] = useState(false);

  return (
    <>
      <LoginReg {...props} />
      <header className={showInPlay ? "inPlay" : ""}>
        <section className={"header " + (isAuthenticated ? " authenticated" : "")}>
          <div className="container">
            <ul className={"header_menu " + (isAuthenticated ? "loggedIn" : "")}>
              {!props.isMobileDeviceByViewPort && (
                <li className={"d-none left_bar " + (expandSearchBar ? "d-none" : "d-block")}>
                  <Link
                    onClick={() => {
                      props.setShowLeftSidebar(true);
                      setTimeout(function () {
                        props.setShowLeftSidebar(false);
                      }, 500);
                    }}
                  >
                    <i className="far fa-bars"></i>
                  </Link>
                </li>
              )}
              <li className="">
                <Link className="header_logo" to="/">
                  <img
                    src={require("assets/images/logos/casino-logo.png").default}
                    onClick={() => {
                      props.setSelectedProviders([]);
                    }}
                    alt="World Golden Casino Logo"
                  />
                </Link>
              </li>

              <li className={"d-none d-lg-block main_menu" + (isAuthenticated ? " loggedin" : "")}>
                <ul className="page_list ">
                  <li>
                    <Link
                      className={location.pathname === "/games/slots" ? "active" : ""}
                      to="/games/slots"
                      onClick={() => {
                        props.setSelectedProviders([]);
                      }}
                    >
                      {__("Casino")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={location.pathname === "/games/live-casino" ? "active" : ""}
                      to="/games/live-casino"
                      onClick={() => {
                        props.setSelectedProviders([]);
                      }}
                    >
                      {__("Live Casino")}
                    </Link>
                  </li>
                  {props.isRacesPageActive && (
                    <li>
                      <Link className={location.pathname === "/races" ? "active" : ""} to="/races">
                        {__("Races")}
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link to="/sports">{__("Sports")}</Link>
                  </li>
                  {/* <li>
                    <Link
                      className={
                        location.pathname === "/games/all" ? "active" : ""
                      }
                      to="/games/all"
                    >
                      Games
                    </Link>
                  </li> */}
                  {/*<li>
                    <Link
                      className={
                        location.pathname === "/games/live-dealer"
                          ? "active"
                          : ""
                      }
                      to="/games/live-dealer"
                    >
                      live casino
                    </Link>
                  </li>*/}
                  <li>
                    <Link className={location.pathname === "/promotions" ? "active" : ""} to="/promotions">
                      {__("Promotions")}
                    </Link>
                  </li>
                </ul>
              </li>
              {!props.isMobileDeviceByViewPort && (
                <li className="d-none d-lg-block">
                  <LanguageSwitcher />
                </li>
              )}
              {isAuthenticated ? (
                <>
                  {!expandSearchBar && (
                    <li className="wallets">
                      <CashierModals {...props} />
                    </li>
                  )}
                  {!props.isMobileDeviceByViewPort && (
                    <>
                      {/* <li
                        className={
                          "notification_icon_container chat_menu_area d-none " +
                          (expandSearchBar ? " d-lg-block" : "d-lg-block")
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => props.setIsChatVisible(true)}
                          className="notification_icon"
                        >
                          <i className="far fa-comments"></i>
                        </Link>

                        
                      </li> */}

                      <li>
                        <OpenChat {...props} />
                      </li>
                    </>
                  )}

                  {props.isMobileDeviceByViewPort && (
                    <li className="header_logo_area d-none">
                      <Link className="header_logo" to="/">
                        <img
                          src={require("assets/images/logos/casino-logo.png").default}
                          alt="World Golden Casino Logo"
                        />
                      </Link>
                    </li>
                  )}
                  <li className={"user_area " + (expandSearchBar ? "d-none d-md-block" : "d-block")}>
                    <Link
                      onClick={() => {
                        props.setShowLeftSidebar(true);
                        setTimeout(function () {
                          props.setShowLeftSidebar(false);
                        }, 500);
                      }}
                      className=" common_btn with_avatar"
                    >
                      {props.userDetails.username && props.userDetails.username !== ""
                        ? props.userDetails.username
                        : props.userDetails.first_name}
                    </Link>

                    {/* <div className="user_avatar">
                      <Link
                        onClick={() => {
                          props.setShowLeftSidebar(true);
                          setTimeout(function () {
                            props.setShowLeftSidebar(false);
                          }, 500);
                        }}
                      >
                        <img
                          className="user_img"
                          src={
                            require("assets/images/header/user-avatar.jpg")
                              .default
                          }
                        />
                      </Link>
                      <div className="online_icon"></div>
                    </div> */}
                  </li>
                </>
              ) : (
                <>
                  {props.isMobileDeviceByViewPort && (
                    <li className="header_logo_area d-none d-md-block">
                      <Link className="header_logo" to="/">
                        <img
                          src={require("assets/images/logos/casino-logo.png").default}
                          alt="World Golden Casino Logo"
                        />
                      </Link>
                    </li>
                  )}
                  <li className={"user_area " + (expandSearchBar ? "d-none d-md-block" : "d-block")}>
                    {props.isMobileDeviceByViewPort && (
                      <Link
                        to=""
                        onClick={() => {
                          props.setModalSignUpPropsData(true);
                          setTimeout(function () {
                            props.setModalSignUpPropsData(false);
                          }, 500);
                        }}
                        style={{ marginLeft: "5px" }}
                        className="common_btn small_btn"
                      >
                        {__("Register")}
                      </Link>
                    )}
                    <Link
                      to=""
                      onClick={() => {
                        props.setModalLoginPropsData(true);
                        setTimeout(function () {
                          props.setModalLoginPropsData(false);
                        }, 500);
                      }}
                      className="common_btn small_btn"
                    >
                      {__("Log In")}
                    </Link>
                  </li>
                  {!props.isMobileDeviceByViewPort && (
                    <li className={"user_area " + (expandSearchBar ? "d-none d-md-block" : "d-block")}>
                      <Link
                        to=""
                        onClick={() => {
                          props.setModalSignUpPropsData(true);
                          setTimeout(function () {
                            props.setModalSignUpPropsData(false);
                          }, 500);
                        }}
                        className="common_btn small_btn"
                      >
                        {__("Register")}
                      </Link>
                    </li>
                  )}
                </>
              )}
            </ul>
          </div>
        </section>
      </header>

      <RightSidebar
        userBalance={props.userBalance}
        userDetails={props.userDetails}
        accountProgress={props.accountProgress}
        rightSidebarOpen={rightSidebarOpen}
        toggleRightSidebar={toggleRightSidebar}
        setShowVaultModal={props.setShowVaultModal}
      />
      {/* <LeftSidebar
        leftSidebarOpen={leftSidebarOpen}
        setLeftSidebarOpen={setLeftSidebarOpen}
        {...props}
      /> */}
      <LeftSidebar {...props} />
      <VaultModals {...props} />
    </>
  );
};

export default Header;

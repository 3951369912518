import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import PromotionBanner from "elements/PromotionBanner";
import { Button } from "react-bootstrap";
import Flickity from "react-flickity-component";
import DOMPurify from "dompurify";
import api from "services/api";
import { __ } from "services/Translator";
import { Helmet } from "react-helmet";

export default function Promotions(props) {
  // const __ = (text) => text;
  const flkty = useRef(null);

  const nextLoad = () => {
    flkty.current.next();
  };

  const prevLoad = () => {
    flkty.current.previous();
  };

  const isAuthenticated = props.isAuthenticated;
  const [pageContent, setPageContent] = useState({});
  const [promotions, setPromotions] = useState([]);

  const getResults = async () => {
    var response = await api.get("/promotions");

    if (response.status === 200) {
      setPageContent(response.data.content_data);
      setPromotions(response.data.promotions);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  const toggleExpanded = (index) => {
    var promotionsClone = JSON.parse(JSON.stringify(promotions));
    promotionsClone[index].expanded = !promotionsClone[index].expanded;
    setPromotions(promotionsClone);
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {__("World Golden Casino | Get Bonuses on your first 2 Deposits, see what else is available here!")}
        </title>
        <meta
          name="description"
          content={__(
            "World Golden Casino! See all the latest fun Promotions we run which give you extra play for your buck - Check back regularly for all the latest news!"
          )}
        />
      </Helmet>

      <PromotionBanner pageName="promotion" {...props} />
      <div className="promotions">
        <div className="container">
          <div className="promotion_list">
            {promotions
              .filter((item) => parseInt(item.priority) === 1)
              .map((promotion, index) => (
                <div className="single_promotion" key={index}>
                  <div className="propotion_left">
                    <div className="promotion_headline">{promotion.subtitle}</div>
                    <div
                      className="paragraph white"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(promotion.details),
                      }}
                    ></div>

                    {props.isAuthenticated ? (
                      <Link
                        className="common_btn no_margin"
                        to="#"
                        onClick={() => props.setOpenWalletModalTab("deposit")}
                      >
                        {__("Get the Deal!")}
                      </Link>
                    ) : (
                      <Link
                        className="common_btn no_margin"
                        onClick={() => {
                          props.setModalSignUpPropsData(true);
                          setTimeout(function () {
                            props.setModalSignUpPropsData(false);
                          }, 500);
                        }}
                      >
                        {__("Get the Deal!")}
                      </Link>
                    )}

                    <br />
                  </div>

                  <div className="promotion_right">
                    <div className={"coins item_" + index}>
                      <div className="dashed"></div>
                      <div className="coin_image">
                        <img src={promotion.image} />
                      </div>
                    </div>

                    <div className="text_area">
                      <div className="terms_btn">
                        <Link className="common_btn small no_margin" to="/bonus-terms">
                          {__("terms")}
                        </Link>
                      </div>
                      <div className="clearfix"></div>

                      <div className="text_heading">{promotion.title}</div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="promotion_bottom_area d-none">
          <div className="container position_relative">
            <div className="promotion_slider">
              <div className="category_games_header">
                <div className="headline_left">
                  <h4 className="game_headline">{__("Other Promotions")}</h4>
                </div>
                <div className="slider_controlls">
                  <Button onClick={prevLoad} className="prev">
                    <i className="fas fa-chevron-left"></i>
                  </Button>
                  <Button onClick={nextLoad} className="prev next">
                    <i className="fas fa-chevron-right"></i>
                  </Button>
                </div>
              </div>
              <Flickity
                flickityRef={(carouselRef) => {
                  flkty.current = carouselRef;
                }}
                options={{
                  groupCells: true,
                  cellAlign: "left",
                  prevNextButtons: false,
                  pageDots: false,
                }}
                reloadOnUpdate
                className="custom_slider"
              >
                {promotions
                  .filter((item) => item.priority === 2)
                  .map((promotion, index) => (
                    <div className="promotion_slide" key={index}>
                      <div className="promotion_image">
                        <img src={promotion.image} />
                      </div>
                      <div className="promiton_detadils">
                        <div className="text">{promotion.title}</div>
                        <div className="icon">
                          <Link to="#">
                            <i className="fas fa-chevron-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </Flickity>
            </div>
            <br />
            <div className="promotion_bottom_banner">
              <img
                className="banner_bottom_bg d-none d-md-block"
                src={require("assets/images/banners/promotion-bottom.png").default}
              />
              <img
                className="banner_bottom_bg d-block d-md-none"
                src={require("assets/images/banners/promotion-bottom-mobile.png").default}
              />
              <div className="banner_text_section">
                <div className="banner_heading">{__("Free VIP Weekly Bonuses Cash, Spins & more.")}</div>
                <div className="banner_dashed"></div>
                <div className="paragraph white text-center no_margin">
                  {__(
                    "Enjoy BIG bonuses, VIP goodies and awards that the 'regular brick' casinos give to their valued VIPs? World Golden Casino has got this and more for all our platinum and diamond VIPs"
                  )}
                  !
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../services/api";
import { __ } from "services/Translator";

export default function BlogDetails() {
  const params = useParams();
  const articleSlug = params.slug;

  const [articleDetails, setArticleDetails] = useState([]);

  const getArticleDetails = async (ev) => {
    var response = await api.get("/article/" + articleSlug);
    if (response.status === 200) {
      setArticleDetails(response.data.data);
    }
    console.log("articleData", response.data);
  };
  useEffect(() => {
    getArticleDetails();
  }, [articleSlug]);

  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  const [popularArticleData, setPopularArticleData] = useState({});
  const getPopularArticleData = async (ev) => {
    var response = await api.post("/popular-articles", {
      limit: 3,
    });
    if (response.status === 200) {
      setPopularArticleData(response.data.data);
    }
    console.log("articleData", response.data);
  };

  const [recentArticleData, setRecentArticleData] = useState({});
  const getRecentArticleData = async (ev) => {
    var response = await api.post("/recent-articles", {
      limit: 3,
    });
    if (response.status === 200) {
      setRecentArticleData(response.data.data);
    }
    console.log("articleData", response.data);
  };

  useEffect(() => {
    getPopularArticleData();
    getRecentArticleData();
  }, []);

  return (
    <>
      <br />
      <br />
      <div className="blogs">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1 className="blog_details_headine">
                {articleDetails != "" && articleDetails.details.title}
              </h1>
              
              <p className="blog_details">
              <img className="single_games_img" src={articleDetails.blog_image_path} />
                {articleDetails != "" &&
                  renderHTML(articleDetails.details.content)}
              </p>
            </div>
            <div className="col-md-4">
              <div className="others_blogs">
                <div>
                  <div className="others_blogs_headline">
                    <div className="common_btn">{__("popular posts")}</div>
                  </div>
                  {popularArticleData.length > 0 &&
                    popularArticleData.map((value, index) => (
                      <div className="other_single_blog">
                        <div className="blog_img">
                          <Link to={"/blog-details/" + value.slug}>
                            <img src={value.blog_image_path} />
                          </Link>
                        </div>
                        <Link
                          to={"/blog-details/" + value.slug}
                          className="paragraph white weight_bold"
                        >
                          {value.blog_content.title}
                        </Link>
                      </div>
                    ))}
                </div>
                <br />
                <div>
                  <div className="others_blogs_headline">
                    <div className="common_btn">{__("recent posts")}</div>
                  </div>
                  {recentArticleData.length > 0 &&
                    recentArticleData.map((value, index) => (
                      <div className="other_single_blog">
                        <div className="blog_img">
                          <Link to={"/blog-details/" + value.slug}>
                            <img src={value.blog_image_path} />
                          </Link>
                        </div>
                        <Link
                          to={"/blog-details/" + value.slug}
                          className="paragraph white weight_bold"
                        >
                          {value.blog_content.title}
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import emailValidate from "services/emailValidate";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import validator from "validator";
import swal from "sweetalert";
import { __ } from "services/Translator";

export default function Login(props) {
  const history = useHistory();
  const setSpinner = props.setSpinner;
  const { updateUserObj } = useContext(AppContext);
  const [errors, setErrors] = useState({});
  // const __ = (text) => text;
  // const params = queryString.parse(window.location.search).p;

  const loginValidateForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    // alert(44);
    if (loginData.email < 2 || loginData.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: __("Please enter your email") };
      valid = false;
    }
    if (loginData.password < 2 || loginData.password.length > 250) {
      errorsTmp = { ...errorsTmp, password: __("Please enter password") };
      valid = false;
    }
    if (!emailValidate(loginData.email)) {
      errorsTmp = { ...errorsTmp, email: __("Your email not valid") };
      valid = false;
    }
    setErrors(errorsTmp);
    return valid;
  };

  const updateLoginForm = (field, value) => {
    setLoginData({ ...loginData, [field]: value });
  };

  const [acceptField, setAcceptField] = useState({
    email: false,
    password: false,
  });

  const loginInputChange = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    if (field == "email") {
      if (emailValidate(value)) {
        setAcceptField({ ...acceptField, email: true });
        setErrors({ ...errors, email: "" });
      } else {
        setAcceptField({ ...acceptField, email: false });
        setErrors({ ...errors, email: __("Your email not valid") });
      }
    }
    if (field == "password") {
      if (
        validator.isStrongPassword(value, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setAcceptField({ ...acceptField, password: true });
        setErrors({ ...errors, password: "" });
      } else {
        setAcceptField({ ...acceptField, password: false });
        setErrors({
          ...errors,
          password:
            __("Your password must contain between 8 and 30 characters, including at least one number, one special character, one lowercase and one upercase. Do not use your email address or username as your password."),
        });
      }
    }

    updateLoginForm(field, value);
  };

  const [loginData, setLoginData] = useState({
    email: "dennis@chimpbytes.com",
    password: "Test12345!",
  });

  const loginAction = async () => {
    var valid = loginValidateForm();
    if (valid) {
      var response = await api.post("/login", loginData);
      setSpinner(false);
      if (response.status === 200) {
        swal({
          title: __("Successfully Logged In!"),
          icon: "success",
        });
        await auth.login(response.data.user);
        updateUserObj();
      } else if (response.status === 423) {
        swal({
          title:
            response.data.message +
            "\n\n" +
            __("If you did not receive the activation email, please close this message to go to the activation page."),
          icon: "warning",
        });
        history.push("/activate-account/" + loginData.email);
      } else {
        swal({
          title: response.data.message,
          icon: "warning",
        });
      }
    }
  };

  return (
    <div className="register">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3">
            <div className="form_area">
              <div className="headline text-center">{__("Login")}</div>
              <div className="login_button d-none">
                <Link to="#" className="common_btn facebook_btn">
                  <i className="fab fa-facebook-square facebook_icon"></i> {__("Login with Facebook")}
                </Link>
                <div className="input_item">
                  <div className="name or_text text-center">{__("or")}</div>
                </div>
              </div>

              <div className="input_item">
                <input
                  onChange={loginInputChange}
                  type="email"
                  name="email"
                  className={
                    acceptField.email
                      ? "form-control name_value border_success"
                      : "form-control name_value"
                  }
                  placeholder={__("Your email")}
                  value={loginData.email}
                />
                <div className="error_msg">{errors.email}</div>
              </div>
              <div className="input_item">
                <input
                  onChange={loginInputChange}
                  type="password"
                  name="password"
                  className={
                    acceptField.password
                      ? "form-control name_value border_success"
                      : "form-control name_value"
                  }
                  placeholder={__("Your password")}
                  value={loginData.password}
                />
                <div className="error_msg">{errors.password}</div>
              </div>

              <div className="login_button">
                <Link to="#" onClick={loginAction} className="common_btn">
                {__("Log in")}
                </Link>
              </div>
              <div className="input_item">
                <div className="name">
                  <Link className="common_link" to="/recover-password">
                    {" "}
                    {__("Forgot Password?")}
                  </Link>
                </div>
              </div>
              <div className="input_item pad_top">
                <div className="name text-center">
                  <Link className="common_link" to="/register">
                  {__("Create an Account")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

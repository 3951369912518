import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import emailValidate from "services/emailValidate";
import validator from "validator";
import swal from "sweetalert";
import api from "services/api";
import { __ } from "services/Translator";

export default function ResetPassword() {
  const history = useHistory();
  var params = useParams();
  // const __ = (text) => text;
  const [errors, setErrors] = useState({});
  // const params = queryString.parse(window.location.search).p;

  const [changePasswordForm, setChangePasswordForm] = useState({
    user_id: params.userId,
    code: params.code,
    password: "",
    confirm_password: "",
  });

  const resetPasswordAction = async () => {
    var valid = validateForm();

    if (valid) {
      var response = await api.post(
        "/reset-password-action",
        changePasswordForm
      );
      console.log("response", response.data);
      if (response.status == 200) {
        // setAlertMsg({
        //   message: response.data.mainMsg,
        //   variant: "success",
        //   showAlert: true,
        // });

        swal({
          title: "Successful!",
          text: response.data.message,
          icon: "success",
        }).then(function () {
          history.push("/");
        });

        setChangePasswordForm({
          user_id: params.userId,
          code: params.code,
          password: "",
          confirm_password: "",
        });
        // setTimeout(() => {
        //   setAlertMsg({
        //     message: response.data.mainMsg,
        //     variant: "success",
        //     showAlert: false,
        //   });
        // }, 7000);
      } else if (response.status == 422) {
        if (response.data.errors) {
          setErrors(response.data.errors);
        } else {
          // setAlertMsg({
          //   message: response.data.mainMsg,
          //   variant: "danger",
          //   showAlert: true,
          // });

          swal({
            title: "Error!!",
            text: response.data.message,
            icon: "error",
          });

          // setInterval(() => {
          //   setAlertMsg({
          //     message: response.data.mainMsg,
          //     variant: "danger",
          //     showAlert: false,
          //   });
          // }, 7000);
        }
      }
      // setTimeout(() => {
      //   setAlertMsg({
      //     message: response.data.mainMsg,
      //     variant: "warning",
      //     showAlert: false,
      //   });
      // }, 7000);
    }
  };

  const validateForm = () => {
    var valid = true;

    var errorsTmp = {};

    // if (
    //   validator.isStrongPassword(changePasswordForm.password, {
    //     minLength: 8,
    //     minLowercase: 1,
    //     minUppercase: 1,
    //     minNumbers: 1,
    //     minSymbols: 1,
    //   })
    // ) {
    // } else {
    //   errorsTmp = {
    //     ...errorsTmp,
    //     password:
    //       "Your password must contain between 8 and 30 characters, including at least one number, one special character, one lowercase and one upercase. Do not use your email address or username as your password.",
    //   };
    //   valid = false;
    // }

    if (
      changePasswordForm.password.length < 8 ||
      changePasswordForm.password.length > 30
    ) {
      errorsTmp = {
        ...errorsTmp,
        password: __("Your password must contain between 8 and 30 characters"),
      };
      valid = false;
    }

    if (changePasswordForm.password != changePasswordForm.confirm_password) {
      errorsTmp = {
        ...errorsTmp,
        confirm_password: __("Password does not match"),
      };
      valid = false;
    }

    setErrors(errorsTmp);
    return valid;
  };

  const updateForm = (field, value) => {
    setChangePasswordForm({ ...changePasswordForm, [field]: value });
  };

  const updateInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    updateForm(field, value);
  };

  const updateSelect = (a, b) => {
    var field = b.name;
    var value = a.value;
    updateForm(field, value);
  };

  // useEffect(() => {
  //   swal({
  //     title: "Successful!",
  //     text: "test",
  //     icon: "success",
  //   }).then(function () {
  //     history.push("/");
  //   });
  // }, []);

  return (
    <div className="register">
      <div className="container">
        <div className="row">
          <div className="col-10 col-sm-10 col-md-8 col-lg-4 offset-1 offset-col-1 offset-sm-1 offset-md-2 offset-lg-4">
            <div className="form_area">
              <div className="common_headline text-center">
                {__("Set your password")}
              </div>
              <br />

              <div className="input_item">
                <div className="name reg_field">{__("New password")}</div>
                <input
                  onChange={updateInput}
                  type="password"
                  name="password"
                  className={"form-control name_value"}
                  placeholder={__("new password")}
                  value={changePasswordForm.password}
                />
                <div className="errorMsg">{errors.password}</div>
              </div>
              <div className="input_item">
                <div className="name reg_field">
                  {__("Confirm new password")}
                </div>
                <input
                  onChange={updateInput}
                  type="password"
                  name="confirm_password"
                  className={"form-control name_value"}
                  placeholder={__("Confirm new password")}
                  value={changePasswordForm.confirm_password}
                />
                <div className="errorMsg">{errors.confirm_password}</div>
              </div>
              <div className="login_button">
                <button
                  onClick={resetPasswordAction}
                  className="common_btn login_btn"
                >
                  {__("Update Password")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import emailValidate from "services/emailValidate";
import validator from "validator";
import swal from "sweetalert";
import api from "services/api";
import { __ } from "services/Translator";

export default function RecoverPassword() {
  const history = useHistory();
  const __ = (text) => text;
  // const [errors, setErrors] = useState({});
  // // const params = queryString.parse(window.location.search).p;

  // const validateForm = () => {
  //   var valid = true;
  //   // return valid;
  //   var errorsTmp = {};
  //   // alert(44);
  //   if (formData.email < 2 || formData.email.length > 250) {
  //     errorsTmp = { ...errorsTmp, email: "Please enter your email" };
  //     valid = false;
  //   }
  //   if (!emailValidate(formData.email)) {
  //     errorsTmp = { ...errorsTmp, email: "Your email not valid" };
  //     valid = false;
  //   }

  //   setErrors(errorsTmp);
  //   return valid;
  // };

  // const updateFormData = (field, value) => {
  //   setFormData({ ...formData, [field]: value });
  // };

  // const [acceptField, setAcceptField] = useState({
  //   email: false,
  // });

  // const formInputChange = (ev) => {
  //   var target = ev.target;
  //   var field = target.name;
  //   var value = target.value;

  //   if (field == "email") {
  //     if (emailValidate(value)) {
  //       setAcceptField({ ...acceptField, email: true });
  //       setErrors({ ...errors, email: "" });
  //     } else {
  //       setAcceptField({ ...acceptField, email: false });
  //       setErrors({ ...errors, email: "Your email not valid" });
  //     }
  //   }

  //   updateFormData(field, value);
  // };
  // const [formData, setFormData] = useState({
  //   email: "",
  //   code: "",
  //   id: "",
  // });

  // const sendAction = () => {
  //   var valid = validateForm();
  //   if (valid) {
  //     swal({
  //       title: "Done!",
  //       text: "instructions has been sent to your email.",
  //       icon: "success",
  //     });
  //     history.push("/login");
  //   }
  // };
  // console.log("Data", formData);

  //forgot Password functionality START'S here
  const [forGotErrors, setForGotErrors] = useState("");
  const [forgotPassForm, setForgotPassForm] = useState({
    email: "",
  });
  const [sentForgotPassMail, setSentForgotPassMail] = useState(false);

  const updateForgotInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    setForgotPassForm({
      email: value,
    });
  };

  const forgotPassword = async () => {
    var errorsTmp = {};

    if (!emailValidate(forgotPassForm.email)) {
      setForGotErrors(__("Please enter a valid email address"));
    } else {
      var response = await api.post("/request-reset-password", forgotPassForm);
      console.log(response);
      if (response.status == 200) {
        // setRegistrationSuccessModal(true);
        // setSentForgotPassMail(true);

        swal({
          title: __("Successful!"),
          text: __(
            "A recovery email sent to you email address, please check your email and recover you password."
          ),
          icon: "success",
        });
        setForgotPassForm({
          email: "",
        });
      } else {
        setForGotErrors(response.data.message);
      }
    }
  };
  //forgot password functionality END'S here

  return (
    <div className="register">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3">
            <div className="form_area">
              <div className="headline text-center">
                {__("Forgot Your Password?")}
              </div>
              <br />
              <div className="input_item">
                <div className="name text-center reg_field">
                  {__(
                    "Enter your registered email address below and password reset instructions will be sent to you."
                  )}
                </div>
              </div>

              <div className="input_item">
                <input
                  onChange={updateForgotInput}
                  type="email"
                  name="email"
                  className={"form-control name_value"}
                  placeholder={__("Your email")}
                  value={forgotPassForm.email}
                />
                <div className="errorMsg">{forGotErrors.email}</div>
              </div>
              <div className="login_button">
                <Link to="#" onClick={forgotPassword} className="common_btn">
                  {__("Send")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import DOMPurify from "dompurify";
import AppContext from "contexts/AppContext";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import ls from "services/ls";
import { __ } from "services/Translator";

export default function FAQ(props) {
  const __ = (text) => text;
  const isAuthenticated = props.isAuthenticated;
  const [faqs, setFaqs] = useState([]);
  const [selectedFaqCategory, setSelectedFaqCategory] = useState({ faqs: [] });

  const getFaqs = async () => {
    props.setSpinner(true);
    var response = await api.get("/get-faqs");
    props.setSpinner(false);
    if (response.status === 200) {
      if (selectedFaqCategory.faqs.length === 0) {
        setSelectedFaqCategory(response.data[0]);
      }
      setFaqs(response.data);
    }
  };

  useEffect(() => {
    getFaqs();
  }, []);
  return (
    <>
      <div className="user_dashboard">
        <br />
        <div className="container-fluid">
          <div className="row">
            <div className="d-block d-lg-none">
              <DropdownButton
                className="mobile_dropbtn"
                title={selectedFaqCategory.category}
              >
                <ul>
                  {faqs.map((faqCategory, index) => (
                    <li key={index}>
                      <Dropdown.Item>
                        <Link
                          to="#"
                          onClick={() => setSelectedFaqCategory(faqCategory)}
                          className={
                            selectedFaqCategory.category ===
                            faqCategory.category
                              ? "active"
                              : ""
                          }
                        >
                          <i className="fas fa-user"></i> {faqCategory.category}
                        </Link>
                      </Dropdown.Item>
                    </li>
                  ))}
                </ul>
              </DropdownButton>
            </div>

            <div className="col-lg-3 col-xl-2 d-none d-lg-block">
              <div className="dashboard_sidebar">
                <ul>
                  {faqs.map((faqCategory, index) => (
                    <li key={index}>
                      <Link
                        to="#"
                        onClick={() => setSelectedFaqCategory(faqCategory)}
                        className={
                          selectedFaqCategory.category === faqCategory.category
                            ? "active"
                            : ""
                        }
                      >
                        <i className="fas fa-user"></i> {faqCategory.category}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-9 col-xl-10">
              <div className="dashboard_content">
                <h3 className="tabName white">{__("Frequently Asked Questions")}</h3>
                <div className="chimp-accordions access_control_area">
                  <Accordion>
                    {selectedFaqCategory.faqs.map((faq, index2) => (
                      <Accordion.Item
                        key={index2}
                        eventKey={index2}
                        className="chimp-accordion"
                      >
                        <Accordion.Header className="faq__question">
                          {faq.title} <i></i>
                        </Accordion.Header>
                        <Accordion.Body className="faq__answer">
                          {faq.description}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

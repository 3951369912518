import logo from "./logo.svg";
import "./App.scss";
// import  "dis/bootstrap/less/bootstrap.less";
import React from "react";
import { OpenRoutes, PrivateRoutes } from "./routes/router";
import auth from "./services/auth";
import Spinner from "./elements/Spinner";
import AppContext from "./contexts/AppContext";
import { Helmet } from "react-helmet";
import SecurityPopup from "elements/SecurityPopup";
import GeoRestriction from "elements/GeoRestriction";
import { __ } from "services/Translator";
import withClearCache from "./ClearCache";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);

  const appContext = React.useMemo(
    () => ({
      updateUserObj: async (data) => {
        // console.log("UserUpdated", data);
        await checkLoggedIn();
      },
    }),
    []
  );

  const checkLoggedIn = async () => {
    var authenticated = await auth.isAuthenticated();
    if (authenticated) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  const init = async () => {
    await checkLoggedIn();
    // if (window.PublicKeyCredential) {
    //   alert("public-key credentials supported");
    // } else {
    //   alert("public-key credentials not supported");
    // }
    auth.checkLastUsed();
    setInterval(() => {
      auth.updateLastUsed();
    }, 5000);
    setIsReady(true);
  };
  React.useEffect(() => {
    init();
  }, []);

  if (isReady) {
    return (
      <>
        <Helmet>
          <title>{__("World Golden Casino | Sign Up today for a 100% of up to 1BTC!")}</title>
          <meta
            name="description"
            content={__(
              "World Golden Casino! Sign Up today and receive bonuses on your first 2 Deposits totalling 2.5 BTC - Double your Coins on us!"
            )}
          />
        </Helmet>
        <AppContext.Provider value={appContext}>
          <GeoRestriction>
            <SecurityPopup>
              {!isLoggedIn && <OpenRoutes {...props} />}
              {isLoggedIn && (
                <React.Fragment>
                  <PrivateRoutes {...props} />
                </React.Fragment>
              )}
            </SecurityPopup>
          </GeoRestriction>
        </AppContext.Provider>
      </>
    );
  } else {
    return <Spinner />;
  }
}

export default App;

import React, { useState, useEffect } from "react";
import Translator, { __ } from "services/Translator";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import api from "services/api";

export default function LanguageSwitcher(props) {
  const history = useHistory();
  const [languages, setLanguages] = useState([]);
  const selectedLanguage = Translator.getSelectedLanguage();
  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      // setLanguages(
      //   response.data.map((val) => {
      //     return { label: val.language, value: val.code };
      //   })
      // );
      setLanguages(response.data);
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const switchLanguage = (lang) => {
    var homeUrl = history.createHref({ pathname: "/" });
    var currentUrl = window.location.href;
    var newUrl = currentUrl.replace(homeUrl, "/" + lang.code + "/");
    Translator.setSelectedLanguage(lang);
    window.location.href = newUrl;
    window.location.href = currentUrl;
  };

  useEffect(() => {
    getLanguages();
  }, []);

  return (
    <Dropdown className={"language_dropdown " + props.className}>
      <Dropdown.Toggle as={Link} className="common_btn  language_dropdown_btn">
        <div className="lang_content">
          {/* <div className="lang_img">
            <img alt="" src={selectedLanguage.flag_path} />
          </div> */}
          {/* <div className="lang_text">Eng</div> */}
          <img
            alt=""
            className="world_icon"
            src={require("../assets/images/icons/world-white.svg").default}
          />
           <img
            alt=""
            className="world_hover_icon"
            src={require("../assets/images/icons/world-gold.svg").default}
          />
          <div className="lang_text">{selectedLanguage.code}</div>
          {/* <div className="lang_text">{selectedLanguage.alias ? selectedLanguage.alias : selectedLanguage.language}</div> */}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {languages.length > 0 &&
          languages.map((value, index) => (
            <Dropdown.Item
              key={index}
              href="javascript:"
              onClick={() => switchLanguage(value)}
            >
              {value.alias ? value.alias : value.language}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

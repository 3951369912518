import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import moneyFormatter from "services/moneyFormatter";
import ls from "services/ls";
import config from "configs/config";
import { isMobile } from "services/isMobile";
import { Modal, Button, Alert, Dropdown, Form, Overlay, Tooltip, Accordion } from "react-bootstrap";
import { __ } from "services/Translator";
import swal from "sweetalert";

function CashierModals(props) {
  const userBalance = props.userBalance;
  const getUserBalance = props.getUserBalance;
  const setUsersWallet = props.setUsersWallet;
  const showInPlay = false;
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const wageringTooltipRef = useRef(null);
  const [wageringTooltipShow, setWageringTooltipShow] = useState(false);
  const tagRef = useRef(null);
  const [tagShow, setTagShow] = useState(false);
  const fiatMethods = ["USD"];

  const minDeposits = {
    BTC: 0.0001,
    ETH: 0.002,
    BNB: 0.01,
    LTC: 0.01,
    DOGE: 1,
    USDTE: 0.01,
    USDTT: 1,
    TRX: 10,
    XRP: 0.001,
  };
  const confirmationsRequired = {
    BTC: 1,
    ETH: 10,
    BNB: 1,
    LTC: 6,
    DOGE: 6,
    USDTE: 10,
    USDTT: 19,
    TRX: 19,
    XRP: 3,
  };

  const __ = (text) => text;

  useEffect(() => {
    var timeout;
    if (wageringTooltipShow) {
      timeout = setTimeout(() => {
        setWageringTooltipShow(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [wageringTooltipShow]);

  const copyDepositAddress = () => {
    var copyText = document.getElementById("deposit_address");
    navigator.clipboard.writeText(copyText.value);
    setShow(true);
    const timer = setTimeout(() => {
      setShow(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  const copyDepositTag = () => {
    var copyText = document.getElementById("deposit_tag");
    navigator.clipboard.writeText(copyText.value);
    setTagShow(true);
    const timer = setTimeout(() => {
      setTagShow(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  // walletModal
  const [depositWallets, setDepositWallets] = useState([]);
  const [selectedDepositWallet, setSelectedDepositWallet] = useState("BTC");

  const [selectedWithdrawalWallet, setSelectedWithdrawalWallet] = useState("BTC");
  const [withdrawalWalletAddress, setWithdrawalWalletAddress] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [withdrawalTag, setWithdrawalTag] = useState("");

  const [showWalletModal, setShowWalletModal] = useState(false);
  const openWalletModal = async () => {
    if (userBalance.wallets.length) {
      setShowWalletModal(true);

      setWithdrawalMessage(null);
      setWithdrawalMessageType("success");

      if (fiatMethods.indexOf(userBalance.wallets[0].currency) === -1) {
        setSelectedDepositWallet(userBalance.wallets[0].currency);
        setSelectedWithdrawalWallet(userBalance.wallets[0].currency);
      }

      var response = await api.get("/deposit/wallets");
      if (response.status === 200) {
        setDepositWallets(response.data);
      }
      getPlayerAcceptBonuses();
    }
  };

  const closeWalletModal = () => {
    setShowWalletModal(false);
    props.setOpenWalletModalTab(null);
  };

  const [showBalanceAsFiat, setShowBalanceAsFiat] = useState(
    ls.get("showBalanceAsFiat") ? ls.get("showBalanceAsFiat") : false
  );
  const [hideEmptyWallets, setHideEmptyWallets] = useState(
    ls.get("hideEmptyWallets") ? ls.get("hideEmptyWallets") : false
  );
  const [fiatCurrencyToDisplay, setFiatCurrencyToDisplay] = useState(
    ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay") : "USD"
  );
  const [showFiatChooserModal, setShowFiatChooserModal] = useState(false);
  const toggleShowBalanceAsFiat = () => {
    if (!showBalanceAsFiat) {
      setShowFiatChooserModal(true);
    }
    ls.set("showBalanceAsFiat", !showBalanceAsFiat);
    setShowBalanceAsFiat(!showBalanceAsFiat);
    props.setTrigger(Math.random());
  };
  const toggleHideEmptyWallets = () => {
    ls.set("hideEmptyWallets", !hideEmptyWallets);
    setHideEmptyWallets(!hideEmptyWallets);
  };
  const fiatToDisplaySelected = (ev) => {
    ls.set("fiatCurrencyToDisplay", ev.target.value);
    setFiatCurrencyToDisplay(ev.target.value);
    props.setTrigger(Math.random());
  };
  const closeFiatChooserModal = () => {
    setShowFiatChooserModal(false);
  };

  const [withdrawalMessage, setWithdrawalMessage] = useState(null);
  const [withdrawalMessageType, setWithdrawalMessageType] = useState("success");

  const withdraw = async () => {
    if (!withdrawalWalletAddress) {
      setWithdrawalMessage("Please enter the " + selectedWithdrawalWallet + " address for withdrawal");
      setWithdrawalMessageType("danger");
      return false;
    }

    if (depositWallets[selectedWithdrawalWallet]?.tag && !withdrawalTag) {
      setWithdrawalMessage("Please enter the tag for withdrawal");
      setWithdrawalMessageType("danger");
      return false;
    }

    var amount = parseFloat(withdrawalAmount);
    if (!amount || isNaN(amount)) {
      setWithdrawalMessage("Please enter a valid amount for withdrawal");
      setWithdrawalMessageType("danger");
      return false;
    } else if (parseFloat(amount) < 50) {
      setWithdrawalMessage("Minimum amount for withdrawal is $50.00");
      setWithdrawalMessageType("danger");
      return false;
    }
    var postData = {
      amount: amount,
      method: "COINSPAID:" + selectedWithdrawalWallet,
      accNumber: withdrawalWalletAddress,
      tag: withdrawalTag,
    };
    var response = await api.post("/withdraw", { ...postData, twofa_code: twoFaCode });
    if (response.status === 200) {
      setWithdrawalAmount("");
      setWithdrawalWalletAddress("");
      setWithdrawalTag("");
      setTwoFaCode("");
      setWithdrawalMessage("Your withdrawal request has been received");
      setWithdrawalMessageType("success");

      setTimeout(() => {
        setWithdrawalMessage(null);
      }, 3000);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  // wallet tabs
  const [activeWalletTab, setActiveWalletTab] = useState("deposit");
  const toggleWalletTab = (tabName) => {
    // if (tabName === "buy_crypto") {
    //   window.open(onRamperWidgetUrl, "_blank");
    //   return;
    // }
    setActiveWalletTab(tabName);
  };

  useEffect(() => {
    if (props.openWalletModalTab) {
      if (props.openWalletModalTab === "deposit") {
        openWalletModal();
        setActiveWalletTab("deposit");
      } else if (props.openWalletModalTab === "withdraw") {
        openWalletModal();
        setActiveWalletTab("withdraw");
      }
    }
  }, [props.openWalletModalTab]);

  useEffect(() => {
    setShowBalanceAsFiat(ls.get("showBalanceAsFiat") ? ls.get("showBalanceAsFiat") : false);
    setFiatCurrencyToDisplay(ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay") : "USD");
  }, [props.trigger]);

  const [playerAcceptBonuses, setPlayerAcceptBonuses] = useState(false);

  const getPlayerAcceptBonuses = async () => {
    var response = await api.get("/player/accept-bonuses");
    if (response.status === 200) {
      setPlayerAcceptBonuses(response.data);
    }
  };

  const togglePlayerAcceptBonuses = async () => {
    var response = await api.post("/player/accept-bonuses");
    if (response.status === 200) {
      setPlayerAcceptBonuses(response.data);
    }
  };

  const userDetails = props.userDetails;
  const [profile, setProfile] = useState({});
  const [twoFaCode, setTwoFaCode] = useState("");
  const getProfile = async () => {
    var response = await api.get("/profile");
    if (response.status === 200) {
      setProfile(response.data);
    }
  };
  const resend2FaCode = async (ev) => {
    props.setSpinner(true);
    var response = await api.post("/resend-twofa");
    props.setSpinner(false);
    if (response.status == 200) {
      swal({
        className: "custom_swal",
        title: __("2FA code sent"),
        text: response.data.message,
        icon: "success",
      });
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const [onRamperWidgetUrl, setOnRamperWidgetUrl] = useState("https://widget.onramper.com?color=266677&apiKey=");

  const updateOnRamperWidgetUrl = () => {
    var url = "https://widget.onramper.com?color=266677";
    url += "&apiKey=" + config.onRamperApiKey;
    url += "&onlyGateways=Xanpool,Itez";
    url += "&supportSell=false";
    url += "&isAddressEditable=true";
    url += "&defaultCrypto=" + selectedDepositWallet;

    var cryptos = userBalance.wallets.map((item, index) => item.currency);
    var wallets = Object.entries(depositWallets)
      .filter((item) => fiatMethods.indexOf(item.currency) === -1)
      .map((item, index) => item[0] + ":" + item[1].address)
      .join(",");
    console.log(wallets);
    url += "&onlyCryptos=" + cryptos;
    url += "&wallets=" + wallets;
    setOnRamperWidgetUrl(url);
  };

  useEffect(() => {
    updateOnRamperWidgetUrl();
  }, [activeWalletTab, depositWallets, selectedDepositWallet]);

  useEffect(() => {
    setBankDepositUrl(null);
  }, [activeWalletTab]);

  const [bankDepositAmount, setBankDepositAmount] = useState("");
  const [bankDepositUrl, setBankDepositUrl] = useState(null);

  const initBankDeposit = async () => {
    var url = "/cashier/paycc/invoice/create";
    var data = { amount: bankDepositAmount };
    var response = await api.post(url, data);

    if (response.status === 200) {
      // var bankUrl = "https://checkout.payop.com/en/payment/invoice-preprocessing/" + response.data.invoiceId;
      // window.location.href = bankUrl;
      // setBankDepositUrl(bankUrl);
      console.log(response.data);
    } else {
      console.log("Error", response);
    }
  };

  const [creditCardData, setCreditCardData] = useState({});
  const updateCreditCardData = (field, value) => {
    setCreditCardData({ ...creditCardData, [field]: value });
  };

  // const initCreditCardDeposit = async () => {
  //   var response = await api.post("/cashier/mobulapay/cc/charge", creditCardData);
  //   console.log(response);
  // };

  const initCreditCardDeposit = async () => {
    var response = await api.post("/cashier/paycc/cc/charge", creditCardData);
    console.log(response);
    if (response.status === 200) {
      swal({
        className: "custom_swal",
        title: __("Thank you !"),
        text: response.data.message,
        icon: "success",
      });
    } else {
      swal({
        className: "custom_swal",
        title: __("Error !!"),
        text: response.data.message,
        icon: "error",
      });
    }
  };

  const [apmsData, setApmsData] = useState({});
  const updateApmsData = (field, value) => {
    setApmsData({ ...apmsData, [field]: value });
  };

  const initApmsDeposit = async () => {
    var response = await api.post("/cashier/mobulapay/invoice/apm/initiate", apmsData);
    console.log(response);
  };

  return (
    <>
      <div className="deposit_block">
        <Dropdown>
          <Dropdown.Toggle className="right wallets_dropdown" id="dropdown-basic1">
            {!showInPlay && (
              <div className="deposit_amount">
                {!!userBalance.wallets?.length
                  ? showBalanceAsFiat
                    ? moneyFormatter.convertCurrencyToFiat(
                        userBalance.wallets[0].balance + userBalance.wallets[0].bonus_balance_crypto,
                        userBalance.wallets[0].currency,
                        fiatCurrencyToDisplay
                      )
                    : parseFloat(userBalance.wallets[0].balance + userBalance.wallets[0].bonus_balance_crypto).toFixed(
                        8
                      )
                  : ""}
              </div>
            )}

            {showInPlay && (
              <div onClick={() => getUserBalance()} className="deposit_amount">
                {__("In Play")}
              </div>
            )}

            <div className="deposit_icon">
              {" "}
              {!!userBalance.wallets?.length && moneyFormatter.currencyIcon(userBalance.wallets[0].currency)}
            </div>
            <div className="wallet_caret">
              <i className="fal downarrow fa-caret-down"></i>
              <i className="fal uparrow fa-caret-up"></i>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="wallets_menu desktop">
            <div className="modal_top_arrow"></div>
            <div>
              <div className="wallets_head row">
                <div className="col-6"></div>
                <div className="col-6 text-right">{__("Crypto")}</div>
              </div>

              {!!userBalance.wallets?.length &&
                userBalance.wallets.map((item, index) => {
                  if (!item.balance && hideEmptyWallets) {
                    return null;
                  }
                  return (
                    <Dropdown.Item as="button" key={index}>
                      <div className="wallets_balance row" key={index} onClick={() => setUsersWallet(item.currency)}>
                        <div className="col-6">
                          {showBalanceAsFiat
                            ? moneyFormatter.convertCurrencyToFiat(item.balance, item.currency, fiatCurrencyToDisplay)
                            : parseFloat(item.balance).toFixed(8)}
                        </div>
                        <div className="col-6 currency">{moneyFormatter.currencyWithIcon(item.currency)}</div>
                      </div>
                    </Dropdown.Item>
                  );
                })}

              {userBalance.bonus_balance && (
                <Dropdown.Item as="button">
                  <div className="wallets_balance row">
                    <div className="col-6">
                      {userBalance.currency + "" + parseFloat(userBalance.bonus_balance).toFixed(2)}
                    </div>

                    <div
                      ref={wageringTooltipRef}
                      onMouseOver={() => setWageringTooltipShow(true)}
                      onMouseOut={() => setWageringTooltipShow(false)}
                      className="col-6 text-right currency"
                    >
                      <span className="currency_icon bonusIcon">
                        <img
                          style={{ width: "20px" }}
                          src={require("../../assets/images/icons/star-icon.svg").default}
                          alt=""
                        />
                      </span>
                      <span className="currency_name">{__("BONUS")}</span>

                      <br />
                    </div>
                    <div className="progress_bar">
                      <div className="progress">
                        <div
                          style={{
                            width: userBalance.wagering_percentage + "%",
                          }}
                          className="progress-bar"
                        ></div>
                      </div>
                    </div>
                  </div>
                </Dropdown.Item>
              )}

              <div className="wallets_bottom row">
                <div className="wallets_switch">
                  <div className="col-11 offset-1">
                    <Form.Check
                      checked={showBalanceAsFiat}
                      type="switch"
                      onChange={toggleShowBalanceAsFiat}
                      label="Show as fiat"
                      id="show_as_fiat"
                    />
                  </div>
                </div>

                <div className="wallets_switch">
                  <div className="col-11 offset-1">
                    <Form.Check
                      checked={hideEmptyWallets}
                      type="switch"
                      label="Hide empty wallets"
                      id="hide_empty_wallets"
                      onChange={toggleHideEmptyWallets}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="deposit_button d-md-none">
                    <a onClick={openWalletModal} className="deposit_btn" href="#">
                      {__("Wallet")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <div className="deposit_button d-none d-md-block">
          <a onClick={openWalletModal} className="deposit_btn" href="#">
            {__("Wallet")}
          </a>
        </div>
      </div>
      <Modal show={showFiatChooserModal} onHide={closeFiatChooserModal}>
        <Modal.Body className="cashier_modal fiat_selectors_modal">
          <div onClick={closeFiatChooserModal} className="modal_close">
            <i className="fal fa-times"></i>
          </div>

          <div className="modal_headline">{__("Information")}</div>
          <>
            <div className="privacy_text">
              {__("Please note that these are currency approximations.")}
              <br />
              <br />
              {__("All bets & transactions will be settled in the crypto equivalent.")}
              {__("For further details feel free to contact our live support.")}
            </div>

            <div className="form_area">
              <div className="form_row fiat_selectors">
                {["USD", "EUR"].map((currency, index) => (
                  <div className="fiat_select" key={index}>
                    <input
                      type="radio"
                      value={currency}
                      checked={fiatCurrencyToDisplay === currency}
                      id={"fiat_selector" + currency}
                      onChange={fiatToDisplaySelected}
                    />
                    <label htmlFor={"fiat_selector" + currency} className="radio_area">
                      <div className="radio_check_and_text_area">
                        <div className="radio_box">
                          <div className="radio_checked_box"></div>
                        </div>
                        <div className="radio_text">
                          {moneyFormatter.currencyIcon(currency)} <span className="currency">{currency}</span>
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>

              <div className="login_button">
                <Link to="#" onClick={closeFiatChooserModal} className="common_btn">
                  {__("Play Now")}
                </Link>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={showWalletModal} onHide={closeWalletModal}>
        <Modal.Body className="cashier_modal">
          <div onClick={closeWalletModal} className="modal_close">
            <i className="fal fa-times"></i>
          </div>

          <div className="modal_headline">Wallet</div>
          <div className="login_mode in_deposit_modal">
            <Link
              to="#"
              onClick={() => toggleWalletTab("deposit")}
              className={activeWalletTab === "deposit" ? "mode_btn active" : "mode_btn"}
            >
              {__("Deposit")}
            </Link>
            <Link
              to="#"
              onClick={() => toggleWalletTab("withdraw")}
              className={activeWalletTab === "withdraw" ? "mode_btn active" : "mode_btn"}
            >
              {__("Withdraw")}
            </Link>
            <Link
              to="#"
              onClick={() => toggleWalletTab("buy_crypto")}
              className={activeWalletTab === "buy_crypto" ? "mode_btn active" : "mode_btn"}
            >
              {__("Buy Crypto")}
            </Link>

            {/* {(userDetails.userId === 8 || userDetails.userId === 15 || true) && ( */}
            <Link
              to="#"
              onClick={() => toggleWalletTab("bank_deposit")}
              className={activeWalletTab === "bank_deposit" ? "mode_btn active" : "mode_btn"}
            >
              {__("Bank Deposit")}
            </Link>
            <Link
              to="#"
              onClick={() => toggleWalletTab("card")}
              className={activeWalletTab === "card" ? "mode_btn active" : "mode_btn"}
            >
              {__("Card")}
            </Link>
            <Link
              to="#"
              onClick={() => toggleWalletTab("apms")}
              className={activeWalletTab === "apms" ? "mode_btn active" : "mode_btn"}
            >
              {__("Others")}
            </Link>
            {/* )} */}
          </div>

          {activeWalletTab === "deposit" && (
            <>
              <div className="form_area">
                <div className="input_item">
                  <div className="name">{__("Cryptocurrency")}</div>

                  <Dropdown className="currency_select_menu">
                    <Dropdown.Toggle variant="success" className="right select_crypto" id="dropdown-basic1">
                      <span className="dipwid vaultCryptoIcon">
                        {moneyFormatter.currencyFullWithIcon(selectedDepositWallet)}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="wallets_menu">
                      {!!userBalance.wallets?.length &&
                        userBalance.wallets
                          .filter((item) => fiatMethods.indexOf(item.currency) === -1)
                          .map((item, index) => {
                            return (
                              <Dropdown.Item as="button" key={index}>
                                <div
                                  className="wallets_balance"
                                  onClick={() => setSelectedDepositWallet(item.currency)}
                                >
                                  {moneyFormatter.currencyFullWithIcon(item.currency)}
                                </div>
                              </Dropdown.Item>
                            );
                          })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="clearfix"></div>
                </div>

                <div className="privacy_text bordered_text">
                  {__("The minimum deposit amount for")} {moneyFormatter.currencyName(selectedDepositWallet)} is{" "}
                  {moneyFormatter.currencyIcon(selectedDepositWallet)} {minDeposits[selectedDepositWallet]} ($
                  {Math.ceil(
                    moneyFormatter.getFiatValue(minDeposits[selectedDepositWallet], selectedDepositWallet, "USD")
                  ).toFixed(2)}{" "}
                  {__("approximately")}).{" "}
                  {__("Depositing a lower amount may result in a full loss of the transferred amount.")}
                  <br />
                  {__("Only send")} {selectedDepositWallet} {__("to this address")},{" "}
                  {confirmationsRequired[selectedDepositWallet]} {__("confirmation")}
                  {confirmationsRequired[selectedDepositWallet] > 1 ? "s" : ""} {__("requried")}.{" "}
                  {__("We do not accept BEP20 from Binance.")}
                </div>

                <div className="input_item walletAddress">
                  <div className="name">
                    {selectedDepositWallet} {__("Address")}
                    <span className="requried_icon">*</span>
                  </div>
                  <input
                    type="text"
                    name="btc"
                    id="deposit_address"
                    value={depositWallets[selectedDepositWallet] ? depositWallets[selectedDepositWallet].address : ""}
                    className="form-control name_value"
                  />

                  <a className="in_input_icon" onClick={copyDepositAddress} href="javascript:" ref={target}>
                    <img alt="" src={require("assets/images/icons/copy-icon.svg").default} />
                  </a>
                  <Overlay target={target.current} show={show} placement="top">
                    {(props) => (
                      <Tooltip id="overlay-example" className="tooltip" {...props}>
                        {__("Copied")}
                      </Tooltip>
                    )}
                  </Overlay>
                </div>

                {depositWallets[selectedDepositWallet]?.tag && (
                  <div className="input_item walletAddress">
                    <div className="name">
                      {selectedDepositWallet} {__("Tag/Memo")}
                      <span className="requried_icon">*</span>
                    </div>
                    <input
                      type="text"
                      name="deposit_tag"
                      id="deposit_tag"
                      value={depositWallets[selectedDepositWallet].tag}
                      className="form-control name_value"
                    />

                    <a className="in_input_icon" onClick={copyDepositTag} href="javascript:" ref={tagRef}>
                      <img alt="" src={require("assets/images/icons/copy-icon.svg").default} />
                    </a>
                    <Overlay target={tagRef.current} show={tagShow} placement="top">
                      {(props) => (
                        <Tooltip id="overlay-example" className="tooltip" {...props}>
                          {__("Copied")}
                        </Tooltip>
                      )}
                    </Overlay>
                  </div>
                )}

                <div className="">
                  <Form.Check
                    checked={playerAcceptBonuses}
                    type="switch"
                    onChange={togglePlayerAcceptBonuses}
                    label={__("Accept bonuses")}
                    id="accept_bonuses"
                  />
                </div>

                <div className="input_item">
                  <div className="qr_code in_wallet_modal">
                    <img
                      className="wallet_qr"
                      src={
                        config.apiUrl +
                        "/qr-code/" +
                        (depositWallets[selectedDepositWallet] ? depositWallets[selectedDepositWallet].qr_text : "")
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {activeWalletTab === "withdraw" && (
            <>
              <div className="form_area">
                <div className="input_item">
                  <div className="name">{__("Cryptocurrency")}</div>

                  {withdrawalMessage && <Alert variant={withdrawalMessageType}>{withdrawalMessage}</Alert>}

                  <Dropdown className="currency_select_menu">
                    <Dropdown.Toggle variant="success" className="right select_crypto" id="dropdown-basic1">
                      <span className="dipwid vaultCryptoIcon">
                        {moneyFormatter.currencyFullWithIcon(selectedWithdrawalWallet)}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="wallets_menu">
                      {!!userBalance.wallets?.length &&
                        userBalance.wallets
                          .filter((item) => fiatMethods.indexOf(item.currency) === -1)
                          .map((item, index) => {
                            return (
                              <Dropdown.Item as="button" key={index}>
                                <div
                                  className="wallets_balance"
                                  onClick={() => setSelectedWithdrawalWallet(item.currency)}
                                >
                                  {moneyFormatter.currencyFullWithIcon(item.currency)}
                                </div>
                              </Dropdown.Item>
                            );
                          })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="clearfix"></div>
                </div>

                <div className="privacy_text bordered_text">{__("Minimum withdrawal is $50.00.")}</div>
                <div className="input_item">
                  <div className="name">
                    {selectedWithdrawalWallet} {__("Address")}
                    <span className="requried_icon">*</span>
                  </div>
                  <input
                    type="text"
                    value={withdrawalWalletAddress}
                    onChange={(ev) => setWithdrawalWalletAddress(ev.target.value)}
                    className="form-control name_value"
                  />
                  {/* <i className="fal fa-copy in_input_icon"></i> */}
                </div>
                <div className="input_item">
                  <div className="name">
                    {__("Amount")}
                    <span className="requried_icon">*</span>
                  </div>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control name_value"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={withdrawalAmount}
                      onChange={(ev) => setWithdrawalAmount(ev.target.value)}
                    />
                    <div className="currency_name">USD</div>
                    <div className="input-group-append">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setWithdrawalAmount(userBalance.wallets[0].balance_fiat.replace(",", ""));
                        }}
                        className="input-group-text max_lavel"
                        id="basic-addon2"
                      >
                        {__("MAX")}
                      </span>
                    </div>
                  </div>
                </div>

                {depositWallets[selectedWithdrawalWallet]?.tag && (
                  <div className="input_item">
                    <div className="name">
                      {__("Tag/Memo")}
                      <span className="requried_icon">*</span>
                    </div>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control name_value"
                        value={withdrawalTag}
                        onChange={(ev) => setWithdrawalTag(ev.target.value)}
                      />
                    </div>
                  </div>
                )}

                {profile.twoFaEnabled && (
                  <div className="input_item">
                    <div className="name">
                      {__("Two FA Code")}
                      <span className="requried_icon">*</span>
                    </div>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control name_value"
                        value={twoFaCode}
                        onChange={(ev) => setTwoFaCode(ev.target.value)}
                      />
                    </div>

                    <a style={{ color: "white" }} href="#" onClick={resend2FaCode}>
                      {__("Get 2FA Code")}
                    </a>
                  </div>
                )}

                <div className="login_button">
                  <Link
                    disabled={withdrawalAmount < 50 || !withdrawalWalletAddress}
                    onClick={withdraw}
                    className={withdrawalAmount < 50 || !withdrawalWalletAddress ? "common_btn inactive" : "common_btn"}
                  >
                    {withdrawalAmount >= 50 ? "Withdraw $" + withdrawalAmount : "Min amount is $50.00"}
                  </Link>
                </div>
              </div>
            </>
          )}

          {activeWalletTab === "buy_crypto" && (
            <>
              <div className="privacy_text bordered_text">&nbsp;</div>
              <div className="form_area">
                <div
                  style={{
                    justifyContent: "center",
                    padding: "15",
                    width: "800px",
                    maxWidth: "90vw",
                  }}
                >
                  <iframe
                    style={{
                      borderRadius: 10,
                      boxShadow: "0 2px 10px 0 rgba(0,0,0,.20)",
                      margin: "auto",
                      width: "100%",
                      maxWidth: "90vw",
                      height: "660px",
                    }}
                    src={onRamperWidgetUrl}
                    title="Onramper widget"
                    frameBorder="0"
                    allow="accelerometer; autoplay; camera; gyroscope; payment"
                  ></iframe>
                </div>
              </div>
            </>
          )}

          {activeWalletTab === "bank_deposit" && (
            <>
              {bankDepositUrl ? (
                <>
                  <div className="privacy_text bordered_text">&nbsp;</div>
                  <div className="form_area">
                    <div
                      style={{
                        justifyContent: "center",
                        padding: "15",
                        width: "800px",
                        maxWidth: "90vw",
                      }}
                    >
                      <iframe
                        style={{
                          borderRadius: 10,
                          boxShadow: "0 2px 10px 0 rgba(0,0,0,.20)",
                          margin: "auto",
                          width: "100%",
                          maxWidth: "90vw",
                          height: "660px",
                        }}
                        src={bankDepositUrl}
                        frameBorder="0"
                      ></iframe>
                    </div>
                  </div>
                </>
              ) : (
                <div className="form_area">
                  <div className="input_item">
                    <div className="name">
                      {__("Amount")}
                      <span className="requried_icon">*</span>
                    </div>
                    <input
                      type="number"
                      min="0"
                      step="1"
                      value={bankDepositAmount}
                      onChange={(ev) => setBankDepositAmount(ev.target.value)}
                      className="form-control name_value"
                    />
                    {/* <i className="fal fa-copy in_input_icon"></i> */}
                  </div>

                  <div className="login_button">
                    <Link onClick={initBankDeposit} className="common_btn">
                      {__("Deposit")}
                    </Link>
                  </div>
                </div>
              )}
            </>
          )}

          {activeWalletTab === "card" && (
            <>
              <div className="form_area">
                <div className="input_item">
                  <div className="name">
                    {__("Amount")}
                    <span className="requried_icon">*</span>
                  </div>
                  <input
                    type="number"
                    min="0"
                    step="1"
                    value={creditCardData.amount}
                    onChange={(ev) => updateCreditCardData("amount", ev.target.value)}
                    className="form-control name_value"
                  />
                  {/* <i className="fal fa-copy in_input_icon"></i> */}
                </div>

                <div className="input_item">
                  <div className="name">
                    {__("Name on card")}
                    <span className="requried_icon">*</span>
                  </div>
                  <input
                    type="text"
                    value={creditCardData.nameOnCard}
                    onChange={(ev) => updateCreditCardData("nameOnCard", ev.target.value)}
                    className="form-control name_value"
                  />
                  {/* <i className="fal fa-copy in_input_icon"></i> */}
                </div>

                <div className="input_item">
                  <div className="name">
                    {__("Card Number")}
                    <span className="requried_icon">*</span>
                  </div>
                  <input
                    type="text"
                    value={creditCardData.cardNumber}
                    onChange={(ev) => updateCreditCardData("cardNumber", ev.target.value)}
                    className="form-control name_value"
                  />
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <div className="input_item">
                      <div className="name">
                        {__("Exp Month")}
                        <span className="requried_icon">*</span>
                      </div>
                      <input
                        type="number"
                        min="1"
                        max="12"
                        step="1"
                        value={creditCardData.expMonth}
                        onChange={(ev) => updateCreditCardData("expMonth", ev.target.value)}
                        className="form-control name_value"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="input_item">
                      <div className="name">
                        {__("Exp Year")}
                        <span className="requried_icon">*</span>
                      </div>
                      <input
                        type="number"
                        min="1"
                        max="12"
                        step="1"
                        value={creditCardData.expYear}
                        onChange={(ev) => updateCreditCardData("expYear", ev.target.value)}
                        className="form-control name_value"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="input_item">
                      <div className="name">
                        {__("CVV")}
                        <span className="requried_icon">*</span>
                      </div>
                      <input
                        type="number"
                        min="1"
                        step="1"
                        value={creditCardData.cvv}
                        onChange={(ev) => updateCreditCardData("cvv", ev.target.value)}
                        className="form-control name_value"
                      />
                    </div>
                  </div>
                </div>

                <div className="input_item">
                  <div className="name">
                    {__("Address")}
                    <span className="requried_icon">*</span>
                  </div>
                  <input
                    type="text"
                    value={creditCardData.address}
                    onChange={(ev) => updateCreditCardData("address", ev.target.value)}
                    className="form-control name_value"
                  />
                </div>

                <div className="login_button">
                  <Link onClick={initCreditCardDeposit} className="common_btn">
                    {__("Deposit")}
                  </Link>
                </div>
              </div>
            </>
          )}

          {activeWalletTab === "apms" && false && (
            <>
              <div className="form_area">
                <div className="input_item">
                  <div className="name">
                    {__("Amount")}
                    <span className="requried_icon">*</span>
                  </div>
                  <input
                    type="number"
                    min="0"
                    step="1"
                    value={apmsData.amount}
                    onChange={(ev) => updateApmsData("amount", ev.target.value)}
                    className="form-control name_value"
                  />
                  {/* <i className="fal fa-copy in_input_icon"></i> */}
                </div>

                <div className="login_button">
                  <Link onClick={initApmsDeposit} className="common_btn">
                    {__("Deposit")}
                  </Link>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CashierModals;

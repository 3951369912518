import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import AppContext from "contexts/AppContext";
import { Button, Modal } from "react-bootstrap";
import Pagination from "elements/Pagination";
import NoRecordsFound from "elements/NoRecordsFound";
import { __ } from "services/Translator";
export default function AccountActivity(props) {
  // const __ = (text) => text;

  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/history/account-activity", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const doReset = () => {
    setForm(defaultForm);
  };

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  return (
    <>
      <h3 className="tabName white">{__("Account Activity")}</h3>

      <div className="user_blocks width_100">
        {results.length ? (
          <>
            {results.map((item, index) => (
              <div className="single_block account_activity">
                <div className="item">
                  <div className="input_label">
                    <svg
                      height="17"
                      width="17"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M510.97268,31.81922C501.16,64.21764,477.1594,140.996,452.15883,199.185c2.90631.89041,5.50012,1.70271,7.90643,2.39a31.03387,31.03387,0,0,1,19.5942,17.69885,31.36973,31.36973,0,0,1-1.18753,26.39987c-22.59427,43.19268-81.22061,149.96374-139.5032,208.24652a198.87529,198.87529,0,0,1-280.91271,0c-77.408-77.38754-77.408-203.45081,0-280.83835C116.36986,114.79916,223.15357,56.11022,266.37331,33.61566a30.5167,30.5167,0,0,1,26.3756-1.18721,31.09638,31.09638,0,0,1,17.71916,19.589c.68752,2.40567,1.59379,4.99879,2.40631,7.90434,58.50134-25.30638,135.09685-49.0975,167.37884-58.892a24.72164,24.72164,0,0,1,30.71946,30.78943ZM476.06563,35.9276C435.65845,48.31523,360.75048,72.51251,309.3743,96.60043l-16.90664,7.90434-5.21887-17.99565c-2.78132-9.70078-5.37513-18.4018-7.37517-24.99395C239.24769,83.80665,135.74531,140.5898,80.65029,195.68585c-64.90774,64.87494-64.90774,170.55252,0,235.53681s170.72267,64.89056,235.59916,0c55.12627-55.08044,111.90883-158.55542,133.72183-200.45154-6.00014-.79668-14.71909-3.38981-24.40681-6.20163l-18.00042-5.18624,7.90643-16.90217C439.37728,151.49341,463.75284,76.30847,476.06563,35.9276Zm-148.19091,276.027c0,70.57668-57.40757,127.96906-128.00294,127.96906a127.96906,127.96906,0,1,1,0-255.93812C270.46715,183.98556,327.96847,241.47166,327.87472,311.95462Zm-32.00073.09372a96.00221,96.00221,0,1,0-96.00221,95.9768C252.873,408.02514,295.96774,364.94181,295.874,312.04834Zm-88.002-32.086a24.00055,24.00055,0,1,1-24.00055-23.9942A23.95369,23.95369,0,0,1,207.872,279.96235Zm32.00074,72.07633a16.00037,16.00037,0,1,1-16.00037-15.99614A16.04933,16.04933,0,0,1,239.8727,352.03868Z" />
                    </svg>
                    {__("Account Action")}
                  </div>
                  <input
                    disabled={true}
                    className="form-controll input_box"
                    value={item.account_action}
                    type="text"
                  />
                </div>

                <div className="item">
                  <div className="input_label">
                    <svg
                      height="17"
                      width="17"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 496 512"
                    >
                      <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm179.3 160h-67.2c-6.7-36.5-17.5-68.8-31.2-94.7 42.9 19 77.7 52.7 98.4 94.7zM248 56c18.6 0 48.6 41.2 63.2 112H184.8C199.4 97.2 229.4 56 248 56zM48 256c0-13.7 1.4-27.1 4-40h77.7c-1 13.1-1.7 26.3-1.7 40s.7 26.9 1.7 40H52c-2.6-12.9-4-26.3-4-40zm20.7 88h67.2c6.7 36.5 17.5 68.8 31.2 94.7-42.9-19-77.7-52.7-98.4-94.7zm67.2-176H68.7c20.7-42 55.5-75.7 98.4-94.7-13.7 25.9-24.5 58.2-31.2 94.7zM248 456c-18.6 0-48.6-41.2-63.2-112h126.5c-14.7 70.8-44.7 112-63.3 112zm70.1-160H177.9c-1.1-12.8-1.9-26-1.9-40s.8-27.2 1.9-40h140.3c1.1 12.8 1.9 26 1.9 40s-.9 27.2-2 40zm10.8 142.7c13.7-25.9 24.4-58.2 31.2-94.7h67.2c-20.7 42-55.5 75.7-98.4 94.7zM366.3 296c1-13.1 1.7-26.3 1.7-40s-.7-26.9-1.7-40H444c2.6 12.9 4 26.3 4 40s-1.4 27.1-4 40h-77.7z" />
                    </svg>
                    {__("IP Address")}
                  </div>
                  <input
                    disabled={true}
                    className="form-controll input_box"
                    value={item.ip_address}
                    type="text"
                  />
                </div>

                <div className="item">
                  <div className="input_label">
                    <i className="fas fa-desktop"></i>
                    {__("Device")}
                  </div>
                  <input
                    disabled={true}
                    className="form-controll input_box"
                    value={item.device}
                    type="text"
                  />
                </div>

                <div className="item">
                  <div className="input_label">
                    <svg
                      height="17"
                      width="17"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-80 80c0-17.7 14.3-32 32-32s32 14.3 32 32-14.3 32-32 32-32-14.3-32-32zm-96 0c0-17.7 14.3-32 32-32s32 14.3 32 32-14.3 32-32 32-32-14.3-32-32zm-96 0c0-17.7 14.3-32 32-32s32 14.3 32 32-14.3 32-32 32-32-14.3-32-32zm272 314c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V192h416v234z" />
                    </svg>
                    {__("Browser")}
                  </div>
                  <input
                    disabled={true}
                    className="form-controll input_box"
                    value={item.browser}
                    type="text"
                  />
                </div>
                <div className="item">
                  <div className="input_label">
                    <i className="far fa-calendar-alt"></i>
                    {__("Date")}
                  </div>
                  <input
                    disabled={true}
                    className="form-controll input_box"
                    value={item.date_created}
                    type="text"
                  />
                </div>
              </div>
            ))}
            <div className="pagination">
              <Pagination data={pagination} onPageChange={onPageChange} />
            </div>
          </>
        ) : (
          <NoRecordsFound />
        )}
      </div>
    </>
  );
}

// var configEnv = "development";
// var configEnv = "staging";
var configEnv = "production";

var config = {
  //##########Live config##########
  production: {
    appUrl: "https://worldgoldencasino.com",
    apiUrl: "https://api.worldgoldencasino.com/api/v1",
    staticUrl: "https://api.worldgoldencasino.com/api/v1",
    liveChatUrl: "https://bossoffice.worldgoldencasino.com/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "https://bossoffice.worldgoldencasino.com/public",
    socketUrl: "wss://api.worldgoldencasino.com:7002",
    debug: false,
    onRamperApiKey: "", //DO not copy, only for blizzcasino
    payOpPublicKey: "",
  },

  //#########Stage config##########
  staging: {
    appUrl: "https://1casinoapi.chimptestserver.com",
    apiUrl: "https://1casinoapi.chimptestserver.com/api/v1",
    staticUrl: "https://1casinoapi.chimptestserver.com/api/v1",
    liveChatUrl: "https://1casinobossoffice.chimptestserver.com/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "https://1casinobossoffice.chimptestserver.com/public",
    socketUrl: "wss://1casinoapi.chimptestserver.com:7002",
    debug: false,
    onRamperApiKey: "", //DO not copy, only for blizzcasino
    payOpPublicKey: "", //Its the production key, there is no staging
  },

  //##########Local config##########
  development: {
    appUrl: "http://localhost:3000",
    apiUrl: "http://casinoapi.local.host/api/v1",
    staticUrl: "http://casinoapi.local.host/api/v1",
    liveChatUrl: "http://casinoadmin.local.host/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "http://casinoadmin.local.host/public",
    socketUrl: "ws://localhost:7002",
    debug: true,
    onRamperApiKey: "", //DO not copy, only for blizzcasino
    payOpPublicKey: "", //Its the production key, there is no staging
  },
};

var finalConfig = config[configEnv];

finalConfig.defaultLanguage = {
  code: "en",
  language: "English",
  flag_path: finalConfig.fileSourceUrl + "/images/flags/svg/gb.svg",
};

export default finalConfig;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import api from "services/api";
import { __ } from "services/Translator";
export default function TransactionHistory(props) {
  const __ = (text) => text;
  const defaultForm = {
    historyFor: "Deposits",
    startDate: null,
    endDate: null,
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/history/transactions", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const doReset = () => {
    setForm(defaultForm);
  };

  const [exportModal, setExportModal] = useState(false);
  const toggleExportModal = () => {
    setExportModal(!exportModal);
  };

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const depositOrWithdrawal = [
    { label: "Deposits", value: "Deposits" },
    { label: "Withdrawals", value: "Withdrawals" },
    { label: "All", value: "All" },
  ];

  const cancelWithdrawal = async (id) => {
    if (window.confirm(__("Are you sure to cancel the withdrawal request?"))) {
      props.setSpinner(true);
      var response = await api.post("/withdraw/cancel", { id });
      props.setSpinner(false);
      if (response.status === 200) {
        getResults();
      } else {
        props.showAlert(null, response.data.message, true);
      }
    }
  };

  const d = new Date();
  let year = d.getFullYear();

  return (
    <>
      <h3 className="tabName white">{__("Transaction History")}</h3>
      <div className="user_blocks">
        <div className="single_block filtering_block">
          <div className="item">
            <div className="input_label">
              <i className="far fa-folder-open"></i>
              {__("History for")}
            </div>
            <SelectBox
              className="form-controll input_box select_box"
              value={form.historyFor}
              onChange={(itemValue) => updateForm(itemValue, "historyFor")}
              items={depositOrWithdrawal}
            />
          </div>

          <div className="item">
            <div className="input_label">
              <i className="far fa-calendar-alt"></i>
              {__("Start Day")}
            </div>
            <DateSelector
              className="filter_group"
              value={form.startDate}
              startYear={2022}
              endYear={year}
              onChange={(itemValue) => updateForm(itemValue, "startDate")}
            />
          </div>

          <div className="item">
            <div className="input_label">
              <i className="far fa-calendar-alt"></i>
              {__("End Day")}
            </div>
            <DateSelector
              className="filter_group"
              value={form.endDate}
              startYear={2022}
              endYear={year}
              onChange={(itemValue) => updateForm(itemValue, "endDate")}
            />
          </div>
        </div>

        <div className="cashier_buttons transaction_buttons_area">
          <Link
            onClick={getResults}
            className="common_btn no_margin box_shadow"
            to="#"
          >
            {__("Search")}
          </Link>
          <Link className="common_btn no_margin box_shadow" to="#">
            {__("Export")}
          </Link>
          <Link
            onClick={doReset}
            className="common_btn no_margin box_shadow"
            to="#"
          >
            {__("Reset")}
          </Link>
        </div>
        <br />

        <div className="width_100">
          {results.length ? (
            <>
              {results.map((item, index) => (
                <div key={index} className="single_block transaction_history">
                  <div className="item">
                    <div className="input_label">
                      <i className="far fa-calendar-alt"></i>
                      {__("Date")}
                    </div>
                    <input
                      disabled={true}
                      type="text"
                      className="form-controll input_box"
                      value={item.action_date}
                    />
                  </div>

                  <div className="item">
                    <div className="input_label">
                      <i className="fas fa-list"></i>
                      {__("Type")}
                    </div>
                    <input
                      disabled={true}
                      type="text"
                      className="form-controll input_box"
                      value={item.type}
                    />
                  </div>

                  <div className="item">
                    <div className="input_label">
                      <svg
                        height="17"
                        width="17"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 496 512"
                      >
                        <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm40.3-221.3l-72-20.2c-12.1-3.4-20.6-14.4-20.6-26.7 0-15.3 12.8-27.8 28.5-27.8h45c11.2 0 21.9 3.6 30.6 10.1 3.2 2.4 7.6 2 10.4-.8l11.3-11.5c3.4-3.4 3-9-.8-12-14.6-11.6-32.6-17.9-51.6-17.9H264v-40c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v40h-7.8c-33.3 0-60.5 26.8-60.5 59.8 0 26.6 18.1 50.2 43.9 57.5l72 20.2c12.1 3.4 20.6 14.4 20.6 26.7 0 15.3-12.8 27.8-28.5 27.8h-45c-11.2 0-21.9-3.6-30.6-10.1-3.2-2.4-7.6-2-10.4.8l-11.3 11.5c-3.4 3.4-3 9 .8 12 14.6 11.6 32.6 17.9 51.6 17.9h5.2v40c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-40h7.8c33.3 0 60.5-26.8 60.5-59.8-.1-26.6-18.1-50.2-44-57.5z" />
                      </svg>
                      {__("Amount")}
                    </div>
                    <input
                      disabled={true}
                      type="text"
                      className="form-controll input_box"
                      value={item.amount}
                    />
                  </div>
                  <div className="item">
                    <div className="input_label">
                      <i className="far fa-credit-card"></i>
                      {__("Method")}
                    </div>
                    <input
                      disabled={true}
                      type="text"
                      className="form-controll input_box"
                      value={item.method}
                    />
                  </div>
                  <div className="item">
                    <div className="input_label">
                      <i className="far fa-info-circle"></i>
                      {__("Status")}
                    </div>
                    <input
                      disabled={true}
                      type="text"
                      className="form-controll input_box"
                      value={item.status}
                    />
                    {item.type === "Withdrawal" && item.status_code === 0 && (
                      <a
                        onClick={() => cancelWithdrawal(item.id)}
                        className="cancel_withdrawal_icon"
                      >
                        <span className="fas fa-times-circle"></span>
                      </a>
                    )}
                  </div>
                </div>
              ))}

              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";
import auth from "services/auth";
import ls, { set } from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import emailValidate from "services/emailValidate";
import LanguageSwitcher from "elements/LanguageSwitcher";
import SupportSidebar from "elements/SupportSidebar";
import { __ } from "services/Translator";
import JackpotTicker from "elements/JackpotTicker";

import { Modal, Button, Alert, Dropdown, Form, Overlay, Tooltip, Collapse, Accordion } from "react-bootstrap";

const Footer = (props) => {
  // const __ = (text) => text;
  const [languages, setLanguages] = useState([]);
  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      // setLanguages(
      //   response.data.map((val) => {
      //     return { label: val.language, value: val.code };
      //   })
      // );
      setLanguages(response.data);
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  useEffect(() => {
    getLanguages();
    // setTimeout(() => {
    //   try {
    //     window.apg_fd182c80_f32b_4a62_a3d5_47644daba979.init();
    //     if (window.location.hostname === "World Golden Casino.io") {
    //       window.xcm_35629cf1_7591_418d_a76f_563332a74c1e.init();
    //     } else {
    //       window.xcm_0fb5404e_463f_45b3_8268_7797d673d57b.init();
    //     }
    //   } catch (ex) {}
    // }, 2000);
  }, []);

  return (
    <>
      <SupportSidebar {...props} />
      <footer>
        <section className="footer">
          <div className="footer_grids landing">
            <div className="container">
              <div className="links_section">
                <div className="single_block">
                  <ul>
                    <li>
                      <Link to="/">
                        {/* <img
                          className="footer_logo"
                          src={
                            require("assets/images/header/footer-logo.png")
                              .default
                          }
                        /> */}
                        <img
                          className="footer_logo"
                          src={require("assets/images/logos/casino-logo.png").default}
                          alt="World Golden Casino Logo"
                        />
                      </Link>
                    </li>
                    <li className="casino_text"></li>
                  </ul>
                  <ul className="supported_currency_list">
                    <li>
                      <span className="currency_icon">
                        <svg className="svg_icon">
                          <use xlinkHref={"/icons.svg#icon-currency-btc"} />
                        </svg>
                      </span>
                    </li>
                    <li>
                      <span className="currency_icon">
                        <svg className="svg_icon">
                          <use xlinkHref={"/icons.svg#icon-currency-eth"} />
                        </svg>
                      </span>
                    </li>
                    <li>
                      <span className="currency_icon">
                        <svg className="svg_icon">
                          <use xlinkHref={"/icons.svg#icon-currency-ltc"} />
                        </svg>
                      </span>
                    </li>
                    <li>
                      <span className="currency_icon">
                        <svg className="svg_icon">
                          <use xlinkHref={"/icons.svg#icon-currency-doge"} />
                        </svg>
                      </span>
                    </li>
                    <li>
                      <span className="currency_icon">
                        <svg className="svg_icon">
                          <use xlinkHref={"/icons.svg#icon-currency-usdt"} />
                        </svg>
                      </span>
                    </li>
                  </ul>
                  <div>
                    <img
                      className="other_mayment_logo"
                      src={require("assets/images/footer/payment_methods.jpg").default}
                    />
                  </div>
                  <div>
                    <img className="Plus_icon" src={require("assets/images/icons/18-logo.svg").default} />
                  </div>
                </div>
                <div className="single_block linkEffect">
                  <ul>
                    <li className="uppercase list_heading">{__("Information")}</li>
                    <li>
                      <Link to="/terms">{__("Terms and Conditions")}</Link>
                    </li>
                    <li>
                      <Link to="/privacy">{__("Privacy policy")}</Link>
                    </li>
                    <li>
                      <Link to="/aml-policy">{__("AML Policy")}</Link>
                    </li>

                    <li>
                      <Link to="/legal">{__("Responsible Gaming")}</Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          props.setSupportSidebarOpen(true);
                        }}
                      >
                        {__("Support")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/vip">{__("VIP Lounge")}</Link>
                    </li>
                    <li>
                      <Link to="/promotions">{__("Promotions")}</Link>
                    </li>
                    <li>
                      <a href="https://affiliates.io" target="_blank">
                        {__("Affiliates")}
                      </a>
                    </li>
                    {props.initialSettings.is_blog_active != 0 && (
                      <li>
                        <Link to="/blogs">{__("Blogs")}</Link>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="single_block linkEffect">
                  <ul>
                    <li className="uppercase list_heading">{__("Services")}</li>
                    <li>
                      <Link to="/games/slots"> {__("Casino")}</Link>
                    </li>
                    <li>
                      <Link to="/games/slots"> {__("Slot Games")}</Link>
                    </li>
                    <li>
                      <Link to="/games/live-casino"> {__("Live Casino")}</Link>
                    </li>
                    {/* <li>
                      <Link to="/games/bonus-buy"> {__("Bonus Buys")}</Link>
                    </li> */}
                    <li>
                      <Link to="/sports">{__("Sports")}</Link>
                    </li>
                    <li>
                      <Link to="/games/jackpot"> {__("Jackpots")}</Link>
                    </li>
                    {/* <li>
                      <Link to="/games/megaways"> {__("Megaways")}</Link>
                    </li> */}
                    {props.isRacesPageActive && (
                      <li>
                        <Link to="/races"> {__("Races")}</Link>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="single_block">
                  <ul>
                    <li className="socialLink">
                      <div className="social_and_language">
                        <ul className="social_links">
                          {/*<li>
                            <Link to="#">
                              <i className="fab fa-facebook"></i>
                            </Link>
                          </li>*/}
                          <li>
                            <a target="_blank" href="https://twitter.com/World Golden Casino">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a target="_blank" href="https://www.instagram.com/World Golden Casino/">
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                          {/* <li>
                            <Link to="#">
                              <i className="fab fa-google-plus-g"></i>
                            </Link>
                          </li> 
                          <li>
                            <Link to="#">
                              <i className="fab fa-linkedin-in"></i>
                            </Link>
                          </li>*/}
                        </ul>
                        <div className="language_selection">
                          <LanguageSwitcher />
                        </div>
                      </div>

                      <div className="socialLinkContainger d-none">
                        {/* <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>*/}
                        <a target="_blank" href="https://twitter.com/World Golden Casino">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a target="_blank" href="https://www.instagram.com/World Golden Casino/">
                          <i className="fab fa-instagram"></i>
                        </a>
                        {/* <Link to="#">
                          <i className="fab fa-google-plus-g"></i>
                        </Link> 
                        <Link to="#">
                          <i className="fab fa-linkedin-in"></i>
                        </Link>*/}
                      </div>
                    </li>
                  </ul>

                  <div className="footer_jackpot_ticker_area">
                    <div className="jackpot_title">{__("JACKPOTS TOTAL")}</div>
                    <JackpotTicker {...props} />
                  </div>
                </div>
              </div>

              {props.isMobileDeviceByViewPort && (
                <div className="license_area">
                  <div className="license_text">
                    {__("World Golden Casino is operated by W & A Darshine N.V.")}
                    <br />{" "}
                    {__(
                      "a company incorporated in Curacao with company registration number 159718 and having its registered address at Abraham de Veerstraat 9, Willemstad, Curaçao."
                    )}
                    <br />{" "}
                    {__(
                      "World Golden Casino is licensed and regulated by the Gaming Authority of Curacao under license number 8048/JAZ."
                    )}
                  </div>

                  {/* <div>
                    <img
                      style={{
                        maxWidth: "200px",
                        margin: "0 auto",
                        display: "block",
                      }}
                      src={
                        require("assets/images/footer/payment_methods.jpg")
                          .default
                      }
                    />
                  </div> */}
                  <div className="footer_copyright_text">
                    copyright © {new Date().getFullYear()} worldgoldencasino.com all rights reserved
                  </div>
                </div>
              )}
            </div>
          </div>

          <ul className="partner_footer_menu d-none">
            <li>
              <a href="mailto:support@World Golden Casino.io">
                <span className="partnerHeading">{__("Support")}:</span>{" "}
                <span className="partnerText">support@World Golden Casino.io</span>
              </a>
            </li>
            <li>
              <a href="mailto:affiliates@World Golden Casino.io">
                <span className="partnerHeading">{__("Partner")}: </span>{" "}
                <span className="partnerText">affiliates@World Golden Casino.io</span>
              </a>
            </li>
            <li>
              <a href="mailto:info@World Golden Casino.io">
                <span className="partnerHeading">{__("General")}:</span>{" "}
                <span className="partnerText">info@World Golden Casino.io</span>
              </a>
            </li>
          </ul>

          <div className="footer_copyright">
            {/* <div className="curacao_license_area">
              <iframe
                style={{
                  width: "150px",
                  height: "50px",
                  border: "none",
                  marginLeft: "64px",
                  verticalAlign: "bottom",
                }}
                src="https://licensing.gaming-curacao.com/validator/?lh=e9f7b57748334a8ad515d52b96331ce4&template=tseal"
              ></iframe>
            </div> */}
            <div className="brands d-none d-lg-block">
              <ul>
                <li className="dotIgamingLogo">
                  <a target="_blank" href="https://dot-igaming.com">
                    <img
                      className="solidImg brand_img"
                      src={require("../assets/images/common/dot-igaming-solid-white.svg").default}
                    />
                    <img
                      className="hoverImg brand_img"
                      src={require("../assets/images/common/dot-igaming.svg").default}
                    />
                  </a>
                </li>
                <li>
                  <img className="brand_img" src={require("../assets/images/footer/payop-logo-white.svg").default} />
                </li>
                <li>
                  <img className="brand_img" src={require("../assets/images/brands/pragmatic.svg").default} />
                </li>
                <li>
                  <img className="brand_img" src={require("../assets/images/brands/evolution.svg").default} />
                </li>

                <li>
                  <img className="brand_img" src={require("../assets/images/brands/netent-logo.svg").default} />
                </li>
                <li>
                  <img className="brand_img" src={require("../assets/images/brands/microgaming-logo.svg").default} />
                </li>

                <li>
                  <img className="brand_img" src={require("../assets/images/brands/hacksaw-gaming.png").default} />
                </li>
                <li>
                  <a target="_blank" href="https://www.begambleaware.org">
                    <img
                      className="brand_img"
                      style={{ marginTop: "-10px", height: "22.5px" }}
                      src={require("../assets/images/brands/begambleawareorg_white.png").default}
                    />
                  </a>
                </li>
                <li>
                  <img className="brand_img" src={require("assets/images/footer/new-brand.svg").default} />
                </li>
              </ul>
            </div>
            <div className="secure_logo">
              {/* <img
                  src={
                    require("assets/images/v1/icons/payment/secured.svg")
                      .default
                  }
                /> */}
              <div className="license_seal ">
                <iframe
                  style={{
                    width: "150px",
                    height: "50px",
                    border: "none",
                    margin: " 20px 0",
                    // marginLeft: "64px",
                    verticalAlign: "bottom",
                  }}
                  src="https://licensing.gaming-curacao.com/validator/?lh=b302e43fc6ac7adc3b8dfc5590af1f04&template=tseal"
                ></iframe>
              </div>
            </div>
            <div className="footer_copyright_text">
              copyright © {new Date().getFullYear()} worldgoldencasino.com all rights reserved
            </div>
            <br />

            <div className="container">
              <div className="info_text">
                {__(
                  "World Golden Casino is operated by W & A Darshine N.V. a company incorporated in Curacao with company registration number 162053 and having its registered address at Abraham de Veerstraat 9, Curacao. Casino Monte Olympus is licensed and regulated by the Gaming Authority of Curacao under license number GLH-OCCHKTW0712142022."
                )}
              </div>
            </div>
            <br />
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;

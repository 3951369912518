import React, { useState, useEffect, useContext } from "react";
import api from "services/api";
import GamesFilter from "elements/games_elements/GamesFilter";
import CategoryGames from "elements/games_elements/CategoryGames";
import CategoryGameSlider from "elements/games_elements/CategoryGameSlider";
import { Helmet } from "react-helmet";
import { useParams, useLocation, useHistory } from "react-router-dom";
export default function Games(props) {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const __ = (text) => text; //Placeholder for future translations function

  const gameTypeAPISlugs = {
    all: "all",
    slots: "video_slots",
    blackjack: "blackjack",
    "video-poker": "table_games",
    "live-dealer": "live_casino",
    "live-casino": "live_casino",
    "bonus-buy": "bonus_buys",
    megaways: "megaways",
    jackpot: "jackpot",
    "table-games": "table_games",
    popular: "popular",
    new: "new",
    search: "search_results",
    "provably-fair": "provably-fair",
  };

  const gameTitles = {
    slots: __("Slots"),
    blackjack: __("Blackjack"),
    "video-poker": __("Video Poker"),
    "live-dealer": __("Live Dealer"),
    "live-casino": __("Live Casino"),
    "bonus-buy": __("Bonus Buys"),
    megaways: __("Megaways"),
    jackpot: __("Jackpot Games"),
    "table-games": __("Table Games"),
    popular: __("Popular"),
    new: __("New"),
    search_results: __("Search Results"),
    "provably-fair": __("Provably Fair"),
  };

  const pageTitles = {
    slots: __("World Golden Casino | Choose slots from Microgaming, Netent, Pragmatic Play & More!"),
    "live-casino": __("World Golden Casino | See all our Live Casino Games with our partners at Evolution and more!"),
    "bonus-buy": __("World Golden Casino | Buy yourself a Bonus game with these Bonus Buys!"),
    megaways: __("World Golden Casino | All your favourite Megaways Games, all in one place!"),
    jackpot: __("World Golden Casino | Change your life with our huge Progressive slots!"),
    "table-games": __("World Golden Casino | Choose from Blackjack, Roulette, Baccarat and more!"),
    popular: __("World Golden Casino | See the most popular Slots with our players today!"),
    new: __("World Golden Casino | See our Newest Games, all in one handy place!"),
    search_results: __("World Golden Casino | Play our wide range of slots and table games!"),
    "provably-fair": __("World Golden Casino | Play our Provably fair Slots - Fairness is guaranteed!"),
    all: __("World Golden Casino | Play our wide range of slots and table games!"),
  };

  const metaDescriptions = {
    slots: __(
      "World Golden Casino! All of your favourite slots in one place including Fruit Party, Rick Wilde in Book of the Dead, Gates of Olympus and more from providers such as Pragmatic Play, Microgaming and Netent!"
    ),
    "live-casino": __(
      "World Golden Casino! A wide range of games you can play as if you were in the casino itself - interact with the Dealers and Croupiers and take part in our Game Shows such as Crazy Time - all thanks to our friends at Evolution!"
    ),
    "bonus-buy": __(
      "World Golden Casino! Fed up of playing Slots where you never get the feature? With these games, you can buy the feature and with it a real chance to win big!"
    ),
    megaways: __(
      "World Golden Casino! Megaways give you a huge numbers of ways to win, each spin is different - with massive Jackpots across many of the Slots, what are you waiting for? Play today!"
    ),
    jackpot: __(
      "World Golden Casino! These Progressive Jackpots are not to be sniffed at, with many into 7 figures, one win on any of these and your life will never be the same again!"
    ),
    "table-games": __(
      "World Golden Casino! All our popular Table Games in one section, choose from different variations of Blackjack, Roulette, Baccarat and more!"
    ),
    popular: __("World Golden Casino! See what Slots your fellow players are using most and join them on an epic journey!"),
    new: __("World Golden Casino! All our newest games in one handy section - check back regularly to see what is new!"),
    search_results: __(
      "World Golden Casino! With a huge range of Slots from some of your favourite providers such as Netent, Microgaming, Pragmatic Play and Hacksaw Gaming, and a wide range of all your favourite Table Games, there is no need to go anywhere else!"
    ),
    "provably-fair": __(
      "World Golden Casino! These Provably Fair slots have been tried and tested to ensure fairness for all - play them now, it could be you!"
    ),
    all: __(
      "World Golden Casino! With a huge range of Slots from some of your favourite providers such as Netent, Microgaming, Pragmatic Play and Hacksaw Gaming, and a wide range of all your favourite Table Games, there is no need to go anywhere else!"
    ),
  };

  const gameType = params.category ? params.category : "all";

  const [pagination, setPagination] = useState(null);
  const [games, setGames] = useState([]);
  const [gameSorting, setGameSorting] = useState(null);
  const [perPage, setPerPage] = useState(20);
  const [gameLoaded, setGameLoaded] = useState(0);
  const [totalGame, setTotalGame] = useState(0);

  const getGames = async (page) => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      game_types: [gameTypeAPISlugs[gameType]],
      page: page,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setIsSearchResultsShowing(false);
      props.setSearchStr("");
      if (page === 1) {
        setGames(response.data.games);
      } else {
        var realGameType = gameTypeAPISlugs[gameType];

        var gamesClone = JSON.parse(JSON.stringify(games));

        gamesClone[realGameType].gamelist = [
          ...gamesClone[realGameType].gamelist,
          ...response.data.games[realGameType].gamelist,
        ];
        setGames(gamesClone);
      }

      setGameLoaded(page === 1 ? response.data.loadedGameCount : gameLoaded + response.data.loadedGameCount);
      setTotalGame(response.data.gameCount);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const onPageChange = (page) => {
    if (gameType === "search") {
      searchGames(page, null);
    } else {
      getGames(page);
    }
  };

  useEffect(() => {
    if (gameType === "search") {
      if (props.searchStr.length) {
        searchGames(1);
      } else {
        history.push("/games/all");
      }
    } else {
      getGames(1);
    }
  }, [gameSorting, gameType, props.searchStr, props.selectedProviders]);

  const searchGames = async (page) => {
    var perPage = 30;
    var serchParams = {
      search_query: props.searchStr,
      page: page,
      per_page: perPage,
      providers: props.selectedProviders,
    };

    var response = await api.post("/games/search", serchParams);
    if (response.status === 200) {
      setIsSearchResultsShowing(true);
      setGames(response.data.games);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }

      // setGameLoaded(page * perPage);

      setGameLoaded(page === 1 ? response.data.loadedGameCount : gameLoaded + response.data.loadedGameCount);

      setTotalGame(response.data.gameCount);
    }
  };

  const [isSearchResultsShowing, setIsSearchResultsShowing] = useState(false);

  return (
    <>
      <Helmet>
        <title>{pageTitles[gameType] ? pageTitles[gameType] : pageTitles["all"]}</title>
        <meta
          name="description"
          content={metaDescriptions[gameType] ? metaDescriptions[gameType] : metaDescriptions["all"]}
        />
      </Helmet>
      <GamesFilter {...props} />
      {/* {gameType === "all" && !props.isMobileDevice && (
        <CategoryGameSlider categoryName="Recommended Games" />
      )} */}

      <CategoryGames
        gameList={games[gameTypeAPISlugs[gameType] ?? "all"]?.gamelist ?? []}
        categoryName={gameTitles[gameType] ?? ""}
        isSearchResults={isSearchResultsShowing}
        category={gameType}
        onPageChange={onPageChange}
        pagination={pagination}
        setGameSorting={setGameSorting}
        gameSorting={gameSorting}
        showPagination={true}
        gameLoaded={gameLoaded}
        totalGame={totalGame}
        providers={props.providers}
        selectedProviders={props.selectedProviders}
        setSelectedProviders={props.setSelectedProviders}
        isAuthenticated={props.isAuthenticated}
        setSearchStr={props.setSearchStr}
        {...props}
      />
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import moneyFormatter from "services/moneyFormatter";
import ls from "services/ls";
import InputBox from "elements/InputBox";
import config from "configs/config";
import { isMobile } from "services/isMobile";
import { __ } from "services/Translator";
import {
  Modal,
  Button,
  Alert,
  Dropdown,
  Form,
  Overlay,
  Tooltip,
  Accordion,
} from "react-bootstrap";

function VaultModals(props) {
  // const __ = (text) => text;
  const userBalance = props.userBalance;
  const getUserBalance = props.getUserBalance;

  const [vaults, setVaults] = useState([]);

  const [depositWallet, setDepositWallet] = useState("BTC");
  const [depositAmount, setDepositAmount] = useState("0.0");

  useEffect(() => {
    setDepositAmount("0.0");
  }, [depositWallet]);

  const setMaxDeposit = () => {
    var wallet = vaults.filter((item) => item.currency === depositWallet)[0];
    var amount = "";
    if (wallet) {
      amount = showBalanceAsFiat
        ? moneyFormatter
            .getFiatValue(
              wallet.balance,
              wallet.currency,
              fiatCurrencyToDisplay
            )
            .toFixed(2)
        : parseFloat(wallet.balance).toFixed(8);
    }
    setDepositAmount(amount);
  };

  const [withdrawalWallet, setWithdrawalWallet] = useState("BTC");
  const [withdrawalAmount, setWithdrawalAmount] = useState("0.0");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setWithdrawalAmount("0.0");
  }, [withdrawalWallet]);

  const setMaxWithdrawal = () => {
    var wallet = vaults.filter((item) => item.currency === withdrawalWallet)[0];
    var amount = "";
    if (wallet) {
      amount = showBalanceAsFiat
        ? moneyFormatter
            .getFiatValue(
              wallet.vault_balance,
              wallet.currency,
              fiatCurrencyToDisplay
            )
            .toFixed(2)
        : parseFloat(wallet.vault_balance).toFixed(8);
    }
    setWithdrawalAmount(amount);
  };

  const showVaultModal = props.showVaultModal;
  const setShowVaultModal = props.setShowVaultModal;

  const [showModal, setShowModal] = useState(false);

  const getVaults = async () => {
    var response = await api.get("/vaults");
    if (response.status === 200) {
      setVaults(response.data);
    } else {
      setVaults([]);
    }
  };

  useEffect(() => {
    if (props.showVaultModal) {
      getVaults();
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [props.showVaultModal]);

  const closeVaultModal = () => {
    setShowVaultModal(false);
  };

  const showBalanceAsFiat = !!ls.get("showBalanceAsFiat");
  const fiatCurrencyToDisplay = ls.get("fiatCurrencyToDisplay") ?? "USD";

  const [withdrawalMessage, setWithdrawalMessage] = useState(null);
  const [withdrawalMessageType, setWithdrawalMessageType] = useState("success");

  const deposit = async () => {
    var response = await api.post("/vault/deposit", {
      selectedWallet: depositWallet,
      amount: depositAmount,
      isAmountInFiat: showBalanceAsFiat,
      fiatCurrency: fiatCurrencyToDisplay,
    });
    if (response.status === 200) {
      alert("Vault deposit successful");
      setDepositAmount("0.0");
      getVaults();
    } else {
      alert(response.data.message);
    }
  };
  const withdraw = async () => {
    var response = await api.post("/vault/withdraw", {
      selectedWallet: withdrawalWallet,
      amount: withdrawalAmount,
      isAmountInFiat: showBalanceAsFiat,
      fiatCurrency: fiatCurrencyToDisplay,
      password: password,
    });
    if (response.status === 200) {
      alert("Vault withdrawal successful");
      setWithdrawalAmount("0.0");
      setPassword("");
      getVaults();
    } else {
      alert(response.data.message);
    }
  };

  // wallet tabs
  const [activeTab, setActiveTab] = useState("deposit");
  const toggleTab = (tabName) => {
    setActiveTab(tabName);
  };

  const formatDepositWalletBalance = (walletCurrency) => {
    var wallet = vaults.filter((item) => item.currency === walletCurrency)[0];
    if (!wallet) {
      return "";
    }
    return showBalanceAsFiat
      ? moneyFormatter.convertCurrencyToFiat(
          wallet.balance,
          wallet.currency,
          fiatCurrencyToDisplay
        )
      : parseFloat(wallet.balance).toFixed(8);
  };

  const formatWithdrawalWalletBalance = (walletCurrency) => {
    var wallet = vaults.filter((item) => item.currency === walletCurrency)[0];
    if (!wallet) {
      return "";
    }
    return showBalanceAsFiat
      ? moneyFormatter.convertCurrencyToFiat(
          wallet.vault_balance,
          wallet.currency,
          fiatCurrencyToDisplay
        )
      : parseFloat(wallet.vault_balance).toFixed(8);
  };

  return (
    <>
      <Modal size="lg" show={showVaultModal} onHide={closeVaultModal}>
        <Modal.Body className="cashier_modal">
          <div onClick={closeVaultModal} className="modal_close">
            <i className="fal fa-times"></i>
          </div>

          <div className="modal_headline">
            <i className="fas fa-lock"></i> {__("Vault")}
          </div>
          <div className="login_mode in_deposit_modal">
            <Link
              to="#"
              onClick={() => toggleTab("deposit")}
              className={
                activeTab === "deposit" ? "mode_btn active" : "mode_btn"
              }
            >
              {__("Deposit")}
            </Link>
            <Link
              to="#"
              onClick={() => toggleTab("withdraw")}
              className={
                activeTab === "withdraw" ? "mode_btn active" : "mode_btn"
              }
            >
              {__("Withdraw")}
            </Link>
          </div>

          {activeTab === "deposit" && (
            <>
              <div className="form_area">
                <div className="input_item">
                  <Dropdown className="currency_select_menu">
                    <Dropdown.Toggle
                      variant="success"
                      className="right select_crypto"
                      id="dropdown-basic1"
                      style={{paddingTop: '0px'}}
                    >
                      <span className="amount">
                        {formatDepositWalletBalance(depositWallet)}
                      </span>{" "}
                      <span className="vaultCryptoIcon currency_name">
                        {moneyFormatter.currencyIcon(depositWallet)}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="wallets_menu">
                      {!!vaults.length &&
                        vaults.map((item, index) => {
                          return (
                            <Dropdown.Item as="button" key={index}>
                              <div
                                className="wallets_balance"
                                onClick={() => setDepositWallet(item.currency)}
                              >
                                <span className="amount">
                                  {formatDepositWalletBalance(item.currency)}
                                </span>{" "}
                                {moneyFormatter.currencyIcon(item.currency)}
                              </div>
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="clearfix"></div>
                </div>

                <div className="input_item">
                  <div className="name">
                    {__("Amount")}
                    <span className="requried_icon">*</span>
                    <span className="available_amount"></span>
                  </div>
                  <div className="input-group">
                    <InputBox
                      type="text"
                      value={depositAmount}
                      onChange={(value) => setDepositAmount(value)}
                      className="form-control name_value"
                    />
                    <div className="currency_name vaultCryptoIcon">
                      {moneyFormatter.currencyIcon(depositWallet)}
                    </div>
                    <div className="input-group-append">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={setMaxDeposit}
                        className="input-group-text max_lavel"
                        id="basic-addon2"
                      >
                        {__("MAX")}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="login_button">
                  <Link
                    onClick={deposit}
                    className={
                      depositAmount < 0 ? "common_btn inactive" : "common_btn"
                    }
                    to="#"
                  >
                    {__("Deposit to Vault")}
                  </Link>
                </div>
              </div>
            </>
          )}

          {activeTab === "withdraw" && (
            <>
              <div className="form_area">
                <div className="input_item">
                  <Dropdown className="currency_select_menu">
                    <Dropdown.Toggle
                      variant="success"
                      className="right select_crypto"
                      id="dropdown-basic1"
                      style={{paddingTop: '0px'}}
                    >
                      <span className="amount">
                        {formatWithdrawalWalletBalance(withdrawalWallet)}
                      </span>{" "}
                      <span className="vaultCryptoIcon currency_name">
                        {moneyFormatter.currencyIcon(withdrawalWallet)}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="wallets_menu">
                      {!!vaults.length &&
                        vaults.map((item, index) => {
                          return (
                            <Dropdown.Item as="button" key={index}>
                              <div
                                className="wallets_balance"
                                onClick={() =>
                                  setWithdrawalWallet(item.currency)
                                }
                              >
                                <span className="amount">
                                  {formatWithdrawalWalletBalance(item.currency)}
                                </span>{" "}
                                {moneyFormatter.currencyIcon(item.currency)}
                              </div>
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="clearfix"></div>
                </div>
                <div className="input_item">
                  <div className="name">
                    {__("Amount")}
                    <span className="requried_icon">*</span>
                    <span className="available_amount"></span>
                  </div>
                  <div className="input-group">
                    <InputBox
                      type="text"
                      value={withdrawalAmount}
                      onChange={(value) => setWithdrawalAmount(value)}
                      className="form-control name_value"
                    />
                    <div className="currency_name vaultCryptoIcon">
                      {moneyFormatter.currencyIcon(depositWallet)}
                    </div>
                    <div className="input-group-append">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={setMaxWithdrawal}
                        className="input-group-text max_lavel"
                        id="basic-addon2"
                      >
                        {__("MAX")}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="input_item walletAddress">
                  <div className="name">
                    {__("Password")}
                    <span className="requried_icon">*</span>
                  </div>
                  <InputBox
                    type="password"
                    value={password}
                    onChange={(value) => setPassword(value)}
                    className="form-control name_value"
                  />
                </div>

                <div className="login_button">
                  <Link
                    onClick={withdraw}
                    className={
                      withdrawalAmount < 0
                        ? "common_btn inactive"
                        : "common_btn"
                    }
                    to="#"
                  >
                    {__("Withdraw from Vault")}
                  </Link>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VaultModals;

import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import AppContext from "contexts/AppContext";
import ls from "services/ls";
import Pagination from "elements/Pagination";
import CategoryGames from "elements/games_elements/CategoryGames";
import GameItem from "elements/games_elements/GameItem";
import NoRecordsFound from "elements/NoRecordsFound";
import { __ } from "services/Translator";

export default function FavoriteGames(props) {
  // const __ = (text) => text;
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/games/favorites", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  return (
    <>
      <h3 className="tabName white">My Games</h3>
      <div className="container">
        {!!results.length ? (
          <>
            <div className="game_list my_games">
              {results.map((game, index) => (
                <GameItem
                  key={game.id}
                  isAuthenticated={props.isAuthenticated}
                  game={game}
                  
                />
              ))}
            </div>

            <br />
            <br />
            <Pagination data={pagination} onPageChange={onPageChange} />
          </>
        ) : (
          <NoRecordsFound content={__("Looks like you did not add any favorite games.")} />
        )}
      </div>
    </>
  );
}

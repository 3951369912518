import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import { __ } from "services/Translator";
import JackpotTicker from "elements/JackpotTicker";

const GameItem = ({ game, isAuthenticated }) => {
  // const __ = (text) => text;
  const [visible, setVisible] = useState(true);
  const defaultImage = require("assets/images/logos/game-loader.gif").default;
  const [gameSnapshotUrl, setGameSnapshotUrl] = useState(defaultImage);
  const [processed, setProcessed] = useState(false);
  // console.log('processedprocessed', processed)
  const hideNoImageFoundGames = () => {
    setVisible(false);
  };

  const [isFavorite, setIsFavorite] = useState(game.is_favorite_game);

  const loadGameImage = () => {
    setGameSnapshotUrl(game.game_snapshot_url);
  };

  const toggleFavoriteGame = async () => {
    var response = await api.post("/toggle-favorite-game", { gameId: game.id });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  };

  useEffect(() => {
    setVisible(true);
    setGameSnapshotUrl(defaultImage);
    setIsFavorite(game.is_favorite_game);
    setProcessed(false);
  }, [game.id, isAuthenticated]);

  const fixImageRatio = (event) => {
    if (!processed) {
      setGameSnapshotUrl(game.game_snapshot_url);
      var img = event.target;
      console.log(img.width);
      var width = img.width;
      var height = width * 0.832;
      img.height = height;
      setTimeout(() => {
        //Sometimes the width is read wrong the first time
        //This is the fallback to fix it
        var width = img.width;
        var height = width * 0.832;
        img.height = height;
      }, 1000);
      setProcessed(true);
    }
  };

  return (
    <div className={"single_game " + (visible ? "" : "d-none")}>
      <div className="games_image">
        <img
          src={gameSnapshotUrl}
          onError={hideNoImageFoundGames}
          onLoad={fixImageRatio}
        />
        {game.has_jackpot != 0 && game.jackpot_amount != 0 && (
          <div className="jackpot_amount">
            <JackpotTicker
              width={15}
              height={20}
              jackpotAmount={game.jackpot_amount_not_formatted}
            />
          </div>
        )}
        <Link
          className="common_btn small game_play_button"
          to={"/play/" + game.slug}
        >
          {__("Play")}
        </Link>
      </div>
      <div className="game_name white">{game.game_name}</div>

      <div className="games_rating">
        <div className="star">
          {/* RTP:{" "}
          <span className="white">{game.rtp ? game.rtp + "%" : "N/A"}</span> */}
          {isAuthenticated && (
            <div onClick={toggleFavoriteGame} className="games_badge">
              <i className={(isFavorite ? "fas" : "far") + " fa-heart"}></i>
            </div>
          )}
        </div>
        {/* <div className="view">
          <i className="fas fa-eye"></i>{" "}
          <span className="count">{game.views}</span>
        </div> */}

        <div className="provider_name white">
          {game.provider?.name ?? "N/A"}
        </div>
      </div>
      <div className="game_overlay">
        {/* <Link
          className="common_btn small game_play_button"
          to={"/play/" + game.slug}
        >
          {__("Play")}
        </Link> */}
      </div>
    </div>
  );
};

export default GameItem;

import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import DOMPurify from "dompurify";
import Banner from "elements/Banner";
import CustomerReviews from "elements/CustomerReviews";
import { Helmet } from "react-helmet";

export default function Vip(props) {
  const isAuthenticated = props.isAuthenticated;
  const __ = (text) => text;
  const [pageContent, setPageContent] = useState({});

  const getResults = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "vip",
    });
    if (response.status === 200) {
      setPageContent(response.data.content_data);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  useEffect(() => {
    getResults();
  }, []);
  return (
    <>
      <Helmet>
        <title>{__("World Golden Casino | Become a VIP and get all the Rewards that go with it!")}</title>
        <meta
          name="description"
          content={__(
            "World Golden Casino! We value all our players of course, become a ViP though and get extra rewards, bonuses and more!"
          )}
        />
      </Helmet>
      {/* <Banner pageName="vip" {...props} /> */}
      <section className="banner header_banner_area">
        <div className="single_banner_area">
          <img
            className="banner_bg desktop_banner"
            src={require("../assets/images/banners/vip-banner-one-desktop.jpg").default}
          />

          <img
            className="banner_bg tab_banner"
            src={require("../assets/images/banners/vip-banner-one-tab.jpg").default}
          />

          <img
            className="banner_bg mobile_banner"
            src={require("../assets/images/banners/vip-banner-one-mobile.jpg").default}
          />

          <div className="container">
            <div className="banner_text_section vip_banner">
              <div className="vip_banner_text">
                {__("start playing today and get a invite to our exclusive vip club!")}
              </div>
              {props.isAuthenticated ? (
                <Link to="/games/live-casino" className="common_btn float-left">
                  {__("Start Winning Now!")}
                </Link>
              ) : (
                <>
                  <Link
                    to=""
                    onClick={() => {
                      props.setModalSignUpPropsData(true);
                      setTimeout(function () {
                        props.setModalSignUpPropsData(false);
                      }, 500);
                    }}
                    className="common_btn float-left"
                  >
                    {__("Start Winning Now!")}
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="about">
        <div className="container">
          <div className="terms_content vip_content">
            <div className="row">
              <div className="col-md-3">
                <div className="review_image">
                  {/* <img
                    className="width_100"
                    src={require("assets/images/home/review.png").default}
                  /> */}
                  <img className="width_100" src={require("assets/images/common/become-a-vip.jpg").default} />
                </div>
                <br />
              </div>
              <div className="col-md-9">
                <div className="page_title">{pageContent?.page_title}</div>

                <div
                  className="paragraph white"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(pageContent.content),
                  }}
                ></div>

                <CustomerReviews isAuthenticated={props.isAuthenticated} />
                <br />
                {isAuthenticated || (
                  <div className="join_btn">
                    <Link className="common_btn no_margin d-none d-xl-block" to="/register">
                      {__("Join Now")}
                    </Link>
                    <Link className="common_btn no_margin small d-block d-xl-none" to="/register">
                      {__("Join Now")}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

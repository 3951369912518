import React, { useEffect, useState } from "react";
import { Button, Modal, Table, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import NoRecordsFound from "elements/NoRecordsFound";
import GameItem from "elements/games_elements/GameItem";
import LoadMore from "elements/LoadMore";
import api from "services/api";
import moment from "moment";
import DOMPurify from "dompurify";
import { __ } from "services/Translator";
import { Helmet } from "react-helmet";

export default function Races(props) {
  const [showDetails, setShowDetails] = useState(false);
  const [raceTab, setRaceTab] = useState("In Progress");
  const [showLeaderBoard, setShowLeaderBoard] = useState(false);

  const [races, setRaces] = useState([]);
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [campaignDetails, setCampaignDetails] = useState("");
  const [campaignGames, setCampaignGames] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [gameLoaded, setGameLoaded] = useState(0);
  const [totalGame, setTotalGame] = useState(0);

  const getRaces = async (raceStatus) => {
    var response = await api.get("/race/list/" + raceStatus);
    if (response.status === 200) {
      setRaces(response.data);
    }
  };

  const [racePage, setRacePage] = useState(null);
  const getRacePage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "races",
    });
    if (response.status === 200) {
      setRacePage(response.data);
    }
  };
  useEffect(() => {
    getRacePage();
  }, []);

  useEffect(() => {
    if (raceTab === "In Progress") {
      getRaces("active");
    } else {
      getRaces("inactive");
    }
  }, [raceTab]);

  const openLeaderBoard = async (id) => {
    setShowLeaderBoard(true);
    var response = await api.get("/race/leaderboard/" + id);
    if (response.status === 200) {
      setLeaderBoard(response.data);
    }
  };

  const openDetails = async (campaign) => {
    setShowDetails(true);
    setCampaignDetails(campaign);
    getRaceGames(campaign.id, 1);
  };

  const getRaceGames = async (campaignId, page) => {
    var response = await api.get("/race/games/" + campaignId + "/" + page);
    if (response.status === 200) {
      setCampaignGames([...campaignGames, ...response.data.games]);
      setGameLoaded(campaignGames.length + response.data.games.length);
      setPagination(response.data.paginationData);
      setTotalGame(response.data.gameCount);
    }
  };

  const onPageChange = (page) => {
    getRaceGames(campaignDetails.id, page);
  };

  const RemainintTime = ({ timestamp }) => {
    const formatTimeStamp = (timestamp) => {
      var ret = "";
      ret += parseInt(timestamp / 3600) + " : ";
      ret +=
        parseInt((timestamp % 3600) / 60)
          .toString()
          .padStart(2, "0") + " : ";
      ret += parseInt(timestamp % 60)
        .toString()
        .padStart(2, "0");
      return ret;
    };

    var latestTimeStamp = timestamp;

    const [formatted, setFormatted] = useState(formatTimeStamp(timestamp));

    useEffect(() => {
      var interval = window.setInterval(() => {
        latestTimeStamp = latestTimeStamp - 1;
        if (latestTimeStamp <= 0) {
          window.clearInterval(interval);
          return 0;
        }
        setFormatted(formatTimeStamp(latestTimeStamp));
      }, 1000);
      return () => {
        window.clearInterval(interval);
      };
    }, [timestamp]);
    return <>{formatted}</>;
  };

  const calculateGridColumnsForGameImage = (gamesCount) => {
    if (!gamesCount) {
      return "repeat(1, 1fr)";
    }
    if (gamesCount > 3) {
      gamesCount = 3;
    }
    return "repeat(" + gamesCount + ", 1fr)";
  };

  return (
    <>
      <Helmet>
        <title>
          {__("World Golden Casino | Race against other players for your chance to win a share of the prize pool!")}
        </title>
        <meta
          name="description"
          content={__(
            "World Golden Casino! View our current and upcoming tournaments where you take on other players - you all start with the same amount of chips, whoever ends with the most wins!"
          )}
        />
      </Helmet>

      <div className="races_page">
        <section className="header_banner_area">
          <div className="single_banner_area">
            <img className="banner_bg desktop_banner" src={racePage?.page?.banner} />

            <img className="banner_bg tab_banner" src={racePage?.page?.banner_tab} />

            <img className="banner_bg mobile_banner" src={racePage?.page?.banner_mobile} />
          </div>
        </section>

        <div className="container">
          <div className="col-md-8 offset-md-2">
            <div className="winner_toggle margin_auto">
              <div
                onClick={() => {
                  setRaceTab("In Progress");
                }}
                className={raceTab === "In Progress" ? "toggle_btn active" : "toggle_btn"}
              >
                {__("In Progress")}
              </div>
              <div
                onClick={() => {
                  setRaceTab("Ended");
                }}
                className={raceTab === "Ended" ? "toggle_btn active" : "toggle_btn"}
              >
                {__("Ended")}
              </div>
            </div>
            <br />
            <div className="race_list">
              {races.length ? (
                <>
                  {races.map((item, index) => (
                    <li>
                      <div className="race_schedule dark_white text-center d-none d-md-block">
                        {moment(item.expiry_date, "YYYY-MM-DD HH:mm:ss").format("MMMM Do YYYY, hh:mm:ss A")}
                      </div>
                      <br />
                      <div className="race_dot d-none d-md-block"></div>
                      <div className="race_border d-none d-md-block"></div>
                      <div className="race_content">
                        <div className="more_btn">
                          <Button
                            onClick={() => {
                              openDetails(item);
                            }}
                            className="details_btn"
                          >
                            <svg
                              name="Checkmark"
                              width="16"
                              height="16"
                              className="race-item__QuestionIcon-sc-14u1juz-1 dmGzWa"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1.649-5.123c-.611.384-.899.596-.899.876v.5a.75.75 0 0 1-1.5 0v-.5c0-.525.203-.998.602-1.404.295-.3.653-.525.999-.742.712-.447 1.024-.687 1.024-1.107 0-.457-.264-.762-.485-.938-.355-.282-.849-.437-1.39-.437-.821 0-1.559.548-1.796 1.333a.75.75 0 0 1-1.436-.432 3.406 3.406 0 0 1 1.2-1.721 3.344 3.344 0 0 1 2.031-.68c.878 0 1.703.271 2.322.762.679.539 1.053 1.289 1.053 2.113 0 1.293-.998 1.92-1.726 2.377z"></path>
                            </svg>
                          </Button>
                        </div>
                        {item.race_banner ? (
                          <div className="race_banner">
                            <img src={item.race_banner} className="race_banner_image" />
                          </div>
                        ) : (
                          <div
                            className="race_banner"
                            style={{
                              gridTemplateColumns: calculateGridColumnsForGameImage(item.games?.length ?? 0),
                            }}
                          >
                            {item.games.map((game, key) => {
                              if (key > 2) {
                                return null;
                              }
                              return <img src={game.game_snapshot_url} className="race_banner_image" />;
                            })}
                          </div>
                        )}

                        <div className="race_details">
                          {item.ending_in ? (
                            <>
                              <h2 className="title">{item.lang?.title}</h2>
                              <div className="paragraph uppercase text-center no_margin">
                                <strong>{item.lang?.campaign_information}</strong>
                              </div>
                              <div className="paragraph uppercase text-center no_margin">{__("ENDING IN")}</div>
                              <div className="time text-center">
                                <RemainintTime timestamp={item.ending_in} />
                              </div>
                            </>
                          ) : (
                            <>
                              <h2 className="title">{item.lang?.title}</h2>
                              <div className="paragraph uppercase text-center no_margin">
                                <strong>{item.lang?.campaign_information}</strong>
                              </div>
                              <div className="paragraph uppercase text-center no_margin">{__("THE RACE HAS")}</div>
                              <div className="time text-center">{__("FINISHED")}</div>
                            </>
                          )}
                          <div className="bonuses">
                            {/*<div className="single_item">
                              <div className="paragraph uppercase text-center no_margin">
                                SPINS
                              </div>
                              <div className="bonus_value">60</div>
                            </div>*/}
                            <div className="single_item">
                              <div className="paragraph uppercase text-center no_margin">{__("MIN BET")}</div>
                              <div className="bonus_value min_bet">${item.minimum_wager}</div>
                            </div>
                            {/*<div className="single_item">
                              <div className="paragraph uppercase text-center no_margin">
                                DURATION
                              </div>
                              <div className="bonus_value">3 days</div>
                            </div>*/}
                          </div>
                          <div className="view_leadboard">
                            <Button
                              onClick={() => {
                                openLeaderBoard(item.id);
                              }}
                              className="load_more_btn"
                            >
                              {__("view leaderboard")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </>
              ) : (
                <>
                  <NoRecordsFound />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={showDetails}>
        <Modal.Body className="cashier_modal">
          <div>
            <Link
              to="#"
              className="modal_close"
              onClick={() => {
                setShowDetails(false);
              }}
            >
              <svg
                width="19"
                height="19"
                className="close-button__IconClose-sc-1o0pd70-1 bMKQZK"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>{__("close")}</title>
                <path d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
              </svg>
            </Link>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(campaignDetails?.lang?.description),
              }}
            ></div>
            <div>
              <h4>{__("Games supported in the race")}</h4>
              <div className="details_games game_list">
                {campaignGames.map((game, index) => (
                  <GameItem game={game} />
                ))}
              </div>
              {pagination && (
                <>
                  <LoadMore data={pagination} onPageChange={onPageChange} />

                  <div className="gameLoadingProgress">
                    <div className="gameLoadingInfo">
                      {__("You've viewed")} {gameLoaded} {__("of")} {totalGame} {__("games")}
                    </div>
                    <ProgressBar variant="success" now={(gameLoaded / totalGame) * 100} />
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showLeaderBoard}>
        <Modal.Body className="leaderboard_modal cashier_modal">
          <div className=" ">
            <Link
              to="#"
              className="modal_close"
              onClick={() => {
                setShowLeaderBoard(false);
              }}
            >
              <svg
                width="19"
                height="19"
                className="close-button__IconClose-sc-1o0pd70-1 bMKQZK"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>{__("close")}</title>
                <path d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
              </svg>
            </Link>
          </div>

          <div className="leaderboard_table">
            <Table>
              <thead>
                <tr>
                  <td>#</td>
                  <td>{__("Player")}</td>
                  <td>{__("Wagered")}</td>
                  <td>{__("Cash Reward")}</td>
                  <td>{__("Bonus")}</td>
                </tr>
              </thead>

              <tbody>
                {leaderBoard.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.player_name}</td>
                    <td>${item.wagered}</td>
                    <td>${item.cash_prize}</td>
                    <td>${item.bonus}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import moneyFormatter from "services/moneyFormatter";
import ls from "services/ls";
import { isMobile } from "services/isMobile";
import { __ } from "services/Translator";

import {
  Modal,
  Button,
  Alert,
  Dropdown,
  Form,
  Overlay,
  Tooltip,
  Accordion,
  ProgressBar,
} from "react-bootstrap";

export default function Cashier(props) {
  // const __ = (text) => text;

  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;

  const [selectedWallet, setSelectedWalelt] = useState(null);
  const [selectItems, setSelectItems] = useState([]);

  useEffect(() => {
    if (props.userBalance.wallets.length) {
      setSelectedWalelt(props.userBalance.wallets[0].currency);
      setSelectItems(
        props.userBalance.wallets.map((item) => {
          return { label: item.currency, value: item.value };
        })
      );
    }
  }, [props.userBalance]);

  const [deleteBonusModal, setDeleteBonusModal] = useState(false);
  const deleteBonusAction = async () => {
    var response = await api.post("/delete-bonus");

    if (response.status === 200 && response.data) {
      setDeleteBonusModal(false);
    }
  };

  const [showBalanceAsFiat, setShowBalanceAsFiat] = useState(
    ls.get("showBalanceAsFiat") ? ls.get("showBalanceAsFiat") : false
  );

  const [fiatCurrencyToDisplay, setFiatCurrencyToDisplay] = useState(
    ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay") : "USD"
  );

  useEffect(() => {
    setShowBalanceAsFiat(
      ls.get("showBalanceAsFiat") ? ls.get("showBalanceAsFiat") : false
    );
    setFiatCurrencyToDisplay(
      ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay") : "USD"
    );
  }, [props.trigger]);

  const toggleShowBalanceAsFiat = () => {
    ls.set("showBalanceAsFiat", !showBalanceAsFiat);
    setShowBalanceAsFiat(!showBalanceAsFiat);
    props.setTrigger(Math.random());
  };

  return (
    <>
      <div className="col-12 col-lg-8">
        <div className="cashier_heading_area">
          <h3 className="tabName white">Cashier</h3>
          <div className="switch_balance_view">
            <label className="crypto_view">{__("Crypto View")}</label>
            <Form.Check
              checked={showBalanceAsFiat}
              type="switch"
              onChange={toggleShowBalanceAsFiat}
              label="Fiat View"
              id="show_as_fiat"
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-8">
        <div className="user_blocks">
          <div className="single_block cashier_currency">
            <div className="item">
              <div className="input_label">
                <svg
                  height="17"
                  width="17"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M334.89 121.63l43.72-71.89C392.77 28.47 377.53 0 352 0H160.15c-25.56 0-40.8 28.5-26.61 49.76l43.57 71.88C-9.27 240.59.08 392.36.08 412c0 55.23 49.11 100 109.68 100h292.5c60.58 0 109.68-44.77 109.68-100 0-19.28 8.28-172-177.05-290.37zM160.15 32H352l-49.13 80h-93.73zM480 412c0 37.49-34.85 68-77.69 68H109.76c-42.84 0-77.69-30.51-77.69-68v-3.36c-.93-59.86 20-173 168.91-264.64h110.1C459.64 235.46 480.76 348.94 480 409zM285.61 310.74l-49-14.54c-5.66-1.62-9.57-7.22-9.57-13.68 0-7.86 5.76-14.21 12.84-14.21h30.57a26.78 26.78 0 0 1 13.93 4 8.92 8.92 0 0 0 11-.75l12.73-12.17a8.54 8.54 0 0 0-.65-13 63.12 63.12 0 0 0-34.17-12.17v-17.6a8.68 8.68 0 0 0-8.7-8.62H247.2a8.69 8.69 0 0 0-8.71 8.62v17.44c-25.79.75-46.46 22.19-46.46 48.57 0 21.54 14.14 40.71 34.38 46.74l49 14.54c5.66 1.61 9.58 7.21 9.58 13.67 0 7.87-5.77 14.22-12.84 14.22h-30.61a26.72 26.72 0 0 1-13.93-4 8.92 8.92 0 0 0-11 .76l-12.84 12.06a8.55 8.55 0 0 0 .65 13 63.2 63.2 0 0 0 34.17 12.17v17.55a8.69 8.69 0 0 0 8.71 8.62h17.41a8.69 8.69 0 0 0 8.7-8.62V406c25.68-.64 46.46-22.18 46.57-48.56.02-21.5-14.13-40.67-34.37-46.7z" />
                </svg>
                {__("Your Balance")}
              </div>
              {/* <input
                disabled={true}
                className="form-controll input_box active"
                value={
                  props.userBalance.currency + props.userBalance.total_balance
                }
                type="text"
              /> */}

              <div className="input_box active">
                {!!props.userBalance.wallets?.length
                  ? !props.userBalance.wallets[0].balance &&
                    props.userBalance.bonus_balance
                    ? props.userBalance.currency +
                      props.userBalance.bonus_balance
                    : showBalanceAsFiat
                    ? moneyFormatter.convertCurrencyToFiat(
                        props.userBalance.wallets[0].balance,
                        props.userBalance.wallets[0].currency,
                        fiatCurrencyToDisplay
                      )
                    : parseFloat(props.userBalance.wallets[0].balance).toFixed(
                        8
                      )
                  : ""}

                {!!props.userBalance.wallets?.length &&
                  moneyFormatter.currencyIcon(
                    props.userBalance.wallets[0].currency
                  )}
              </div>
            </div>

            <div className="item">
              <div className="input_label">
                <i className="fas fa-dollar-sign"></i>
                {__("Currency")}
                <i className="fas fa-star highlight"></i>
              </div>
              <SelectBox
                className="form-controll input_box select_box"
                value={selectedWallet}
                onChange={(value) => props.setUsersWallet(value)}
                items={selectItems}
              />
            </div>
          </div>
          <div className="single_block cashier_balance">
            <div className="item">
              <div className="input_label">
                <i className="far fa-money-bill-alt"></i>
                {__("Cash Balance")}
              </div>
              {/* <input
                disabled={true}
                className="form-controll input_box"
                value={
                  props.userBalance.currency + props.userBalance.cash_balance
                }
                type="text"
              /> */}

              <div className="input_box">
                {!!props.userBalance.wallets?.length
                  ? showBalanceAsFiat
                    ? moneyFormatter.convertCurrencyToFiat(
                        props.userBalance.wallets[0].balance,
                        props.userBalance.wallets[0].currency,
                        fiatCurrencyToDisplay
                      )
                    : parseFloat(props.userBalance.wallets[0].balance).toFixed(
                        8
                      )
                  : ""}

                {!!props.userBalance.wallets?.length &&
                  moneyFormatter.currencyIcon(
                    props.userBalance.wallets[0].currency
                  )}
              </div>
            </div>
            <div className="item">
              <div className="input_label">
                <i className="far fa-gem"></i>
                {__("Bonus Balance")}
              </div>
              <input
                disabled={true}
                className="form-controll input_box"
                value={
                  props.userBalance.currency + props.userBalance.bonus_balance
                }
                type="text"
              />
            </div>
          </div>

          <div className="single_block  wagerBar">
            <div className="item"></div>
            <div className="item">
              <div className="gameLoadingProgress wagerRequirementBar">
                <div className="gameLoadingInfo">
                  {__("Achieved")}{" "}
                  {props.userBalance.wagering_requirements_achieved} {__("of")}{" "}
                  {props.userBalance.wagering_requirements_total}{" "}
                  {__("Wagering Requirements")}
                </div>
                <ProgressBar
                  variant="success"
                  label={
                    Math.round(props.userBalance.wagering_percentage) + "%"
                  }
                  now={props.userBalance.wagering_percentage}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div className="col-12">
        <div className="cashier_buttons">
          <ul className="d-flex">
            <li>
              <Link
                onClick={() => props.setOpenWalletModalTab("deposit")}
                className="common_btn no_margin box_shadow"
              >
                {__("Deposit")}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => props.setOpenWalletModalTab("withdraw")}
                className="common_btn pink no_margin box_shadow"
              >
                {__("Withdraw")}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => props.setShowVaultModal(true)}
                className="common_btn pink no_margin box_shadow"
              >
                <i className="fas fa-lock"></i> {__("Vault")}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setDeleteBonusModal(true);
                }}
                className="common_btn pink no_margin box_shadow"
              >
                {__("Delete Bonus")}
              </Link>
              <div className="smallWhiteText">
                {__("(and wagering requirement)")}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Modal show={deleteBonusModal}>
        <Modal.Body className="login_modal forgot_pass">
          <div
            onClick={() => {
              setDeleteBonusModal(false);
            }}
            className="modal_close"
          >
            <i className="fal fa-times"></i>
          </div>
          <div className="modal_headline">
            {__("Are you sure to delete your bonus balance?")}
            <br />
            <br />
            <div className="d-flex">
              <Link
                onClick={() => {
                  setDeleteBonusModal(false);
                }}
                className="common_btn"
              >
                {__("No")}
              </Link>
              <Link onClick={deleteBonusAction} className="common_btn">
                {__("Yes")}
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import { Badge, Button } from "react-bootstrap";
import { __ } from "services/Translator";

const CategoryGameSlider = (props) => {
  // const menuOpen = props.menuOpen;
  // const __ = (text) => text;
  const [categoryName, setCategoryName] = useState(props.categoryName);

  return (
    <>
      <section className="category_games">
        <div className="container">
          {!!categoryName && (
            <div className="category_games_header">
              <div className="headline_left">
                <h4 className="game_headline">{categoryName}</h4>
              </div>
            </div>
          )}
          <div className="custom_slider">
            <div className="single_slide">
              <img
                className="slide_bg_image"
                src={require("assets/images/slider/slot-machine2.png").default}
              />
              <div className="slide_text_section">
                <Badge className="white_badge">
                  {/* <i className="fas fa-eye"></i> 264536 */}
                  &nbsp;
                </Badge>

                <div className="buttons">
                  {props.isAuthenticated ? (
                    <Link
                      className="common_btn pink no_margin"
                      to="/games/slots"
                    >
                      {__("Play Now")}
                    </Link>
                  ) : (
                    <Link
                      className="common_btn pink no_margin"
                      onClick={() => {
                        props.setModalSignUpPropsData(true);
                        setTimeout(function () {
                          props.setModalSignUpPropsData(false);
                        }, 500);
                      }}
                    >
                      {__("Play Now")}
                    </Link>
                  )}
                </div>

                <div className="game_description">
                  <div className="game_name white">{__("Slot Games")}</div>
                  <div className="game_provider white">&nbsp;</div>
                </div>
              </div>
            </div>
            <div className="single_slide">
              <img
                className="slide_bg_image"
                src={require("assets/images/slider/live-dealer.png").default}
              />
              <div className="slide_text_section">
                <Badge className="white_badge">
                  {/* <i className="fas fa-eye"></i> 264536 */}
                  &nbsp;
                </Badge>

                <div className="buttons">
                  {props.isAuthenticated ? (
                    <Link
                      className="common_btn dark_blue no_margin"
                      to="/games/live-casino"
                    >
                      {__("Play Now")}
                    </Link>
                  ) : (
                    <Link
                      className="common_btn dark_blue no_margin"
                      onClick={() => {
                        props.setModalSignUpPropsData(true);
                        setTimeout(function () {
                          props.setModalSignUpPropsData(false);
                        }, 500);
                      }}
                    >
                      {__("Play Now")}
                    </Link>
                  )}
                </div>

                <div className="game_description">
                  <div className="game_name white">{__("Live Casino")}</div>
                  <div className="game_provider white">&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryGameSlider;

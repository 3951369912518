import React, { useState, useEffect } from "react";
import auth from "services/auth";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import { __ } from "services/Translator";

const StickyFooterMenu = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [showWalletModal, setShowWalletModal] = useState(false);
  useEffect(() => {
    // alert(showWalletModal)
    if (showWalletModal == true) {
      props.showWalletModal(showWalletModal);
    }
  }, [showWalletModal]);

  //Search functionality
  const [showSearch, setShowSearch] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const updateSearchInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    setSearchVal(value);
  };

  // useEffect(() => {
  //   props.searchVal(searchVal);
  // }, [searchVal]);

  //GamePlay Page detection
  const location = useLocation();

  const [showSlotsLink, setShowSlotsLink] = useState(false);

  useEffect(() => {
    const playPath = location.pathname.split("/");
    setShowSlotsLink(playPath.includes("live-casino"));
  }, [location]);

  return (
    <div className="sticky_footer_menu_area">
      {/*
      <div className={"sticky_search_area " + (showSearch ? "showSearch" : '')} >
        <input onChange={updateSearchInput} name="search_value" value={searchVal} type="text" />
      </div>
      */}
      <ul>
        {!isAuthenticated && (
          <li>
            <Link to="/">
              <i class="fas fa-home"></i>
              <div className="menu_text">{__("Home")}</div>
            </Link>
          </li>
        )}
        {isAuthenticated && (
          <li>
            <Link onClick={() => props.setOpenWalletModalTab("deposit")}>
              <i class="fas fa-wallet"></i>
              <div className="menu_text">{__("Wallet")}</div>
            </Link>
          </li>
        )}

        <li>
          <Link to={{ pathname: "/", state: { focusSearch: true } }}>
            <i class="fas fa-search"></i>
            <div className="menu_text">{__("Search")}</div>
          </Link>
        </li>

        {showSlotsLink ? (
          <li>
            <Link to={{ pathname: "/games/slots" }}>
              <svg className="svg_icon">
                <use
                  xlinkHref={
                    require("assets/images/common/game_icons/game_icons.svg")
                      .default + "#slots"
                  }
                />
              </svg>
              <div className="menu_text">{__("Slots")}</div>
            </Link>
          </li>
        ) : (
          <li>
            <Link to={{ pathname: "/games/live-casino" }}>
              <svg className="svg_icon">
                <use
                  xlinkHref={
                    require("assets/images/common/game_icons/game_icons.svg")
                      .default + "#live_casino"
                  }
                />
              </svg>
              <div className="menu_text">{__("Live")}</div>
            </Link>
          </li>
        )}

        <li>
          <Link
            onClick={() => {
              props.setSupportSidebarOpen(true);
              // setTimeout(function () {
              //   props.setSupportSidebarOpen(false);
              // }, 500);
            }}
          >
            <i class="far fa-comment-dots"></i>
            <div className="menu_text">{__("Support")}</div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default StickyFooterMenu;
